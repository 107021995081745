import {
    createAsyncThunk,
    createAction,
} from "@reduxjs/toolkit";

import { apiClient } from "../../_helpers";
import {
    Project,
    AlertType,
    ProjectFiltersOprions,
    ProjectDto,
    ProjectFiltersApplied,
    FileManager,
    ArchiveBy,
} from "../../_types";
import { RootState, alertActions } from "..";
import { API_ENDPOINTS, ProjectStatuses } from "../../_constants";
import { generateAlert } from "../../_helpers/alertGeneration.helper";

export const NAME = "project";
export const extraActions = createExtraActions();

function createExtraActions() {
    return {
        getAllProjects: getAllProjects(),
        selectProject: selectProject(),
        sendProject: sendProject(),
        forceSendProjectLoader: forceSendProjectLoader(),
        hideAlert: hideAlert(),
        getFilters: getFilters(),
        setFilters: setFilters(),
        applyFilters: applyFilters(),
        getAllFiles: getAllFiles(),
        updateProject: updateProject(),
        sendFiles: sendFiles(),
        deleteFile: deleteFile(),
        exportProjectsData: exportProjectsData(),
        getDiscSpace: getDiscSpace(),
        archiveBy: archiveBy(),
    };

    function hideAlert() {
        return createAction(`${NAME}/hideAlert`);
    }

    function forceSendProjectLoader() {
        return createAction(`${NAME}/forceSendProjectLoader`);
    }

    function deleteFile() {
        return createAsyncThunk<any, FileManager, { state: RootState }>(
            `${NAME}/deleteFile`,
            async (data, thunkAPI) => {
                const response = await apiClient.post<any>(
                    API_ENDPOINTS.deleteFile,
                    data
                );
                if (response.status === 201) {
                    return response.data;
                } else {
                    throw new Error("cant process query to external API");
                }
            }
        );
    }

    function sendFiles() {
        return createAsyncThunk<any, FileManager, { state: RootState }>(
            `${NAME}/sendFiles`,
            async (data, thunkAPI) => {
                try {
                    const response = await apiClient.post<void>(
                        API_ENDPOINTS.files,
                        data
                    );
                    if (response.status === 201) {
                        const projectId = thunkAPI.getState().project.selectedProject.id
                        thunkAPI.dispatch(extraActions.selectProject(projectId))
                        return response.data;
                    } else {
                        throw new Error("cant process query to external API");
                    }
                } catch (error: any) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error;
                }
            }
        );
    }

    function exportProjectsData() {
        return createAsyncThunk<void, void, { state: RootState }>(
            `${NAME}/exportProjectsData`,
            async (_, thunkAPI) => {
                const state = thunkAPI.getState();
                try {
                    const response = await apiClient.post<Blob>(
                        `${API_ENDPOINTS.project}/dataExport`,
                        state.project.appliedFilters,
                        {
                            responseType: "blob",
                        }
                    );
                    if (response.status === 200) {
                        const startDate = new Date(
                            state.project.appliedFilters.startDate || ""
                        ).toDateString();
                        const endDate = new Date(
                            Number(new Date(state.project.appliedFilters.endDate || "")) - 1
                        ).toDateString();
                        const href = URL.createObjectURL(response.data);
                        const link = document.createElement("a");
                        link.href = href;
                        link.setAttribute(
                            "download",
                            `Projects ${startDate}-${endDate}.xlsx`
                        ); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    } else {
                        throw new Error("Can't process query to external API");
                    }
                } catch (error: any) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error;
                }
            }
        );
    }

    function updateProject() {
        return createAsyncThunk<Project, Project, { state: RootState }>(
            `${NAME}/updateProject`,
            async (updatedProject, thunkAPI) => {
                try {
                    const response = await apiClient.patch<Project>(
                        `${API_ENDPOINTS.project}/${updatedProject.id}`,
                        updatedProject
                    );
                    if (response.status === 200) {
                        thunkAPI.dispatch(
                            alertActions.setMessage({
                                alert: { type: "success", message: "ProjectUpdated" },
                            })
                        );
                        return response.data;
                    } else {
                        throw new Error("Can't process query to external API");
                    }
                } catch (error: any) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error;
                }
            }
        );
    }

    function getFilters() {
        return createAsyncThunk<ProjectFiltersOprions, void, { state: RootState }>(
            `${NAME}/getFilters`,
            async (_, thunkAPI) => {
                try {
                    const response = await apiClient.get<ProjectFiltersOprions>(
                        `${API_ENDPOINTS.project}/filters`
                    );
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        throw new Error("Can't process query to external API");
                    }
                } catch (error: any) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error;
                }
            }
        );
    }

    function selectProject() {
        return createAsyncThunk<Project, string, { state: RootState }>(
            `${NAME}/selectProject`,
            async (projectId, thunkAPI) => {
                try {
                    const response = await apiClient.get<Project>(
                        `${API_ENDPOINTS.project}/${projectId}`
                    );
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        throw new Error("Can't process query to external API");
                    }
                } catch (error: any) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error;
                }
            }
        );
    }

    function setFilters() {
        return createAction(
            `${NAME}/setFilters`,
            function prepare({
                skipGettingProjects,
                filters,
            }: {
                skipGettingProjects?: boolean;
                filters: ProjectFiltersApplied;
            }) {
                return {
                    payload: {
                        filters,
                        skipGettingProjects,
                    },
                };
            }
        );
    }

    function applyFilters() {
        return createAsyncThunk<
            void,
            { skipGettingProjects?: boolean; filters: ProjectFiltersApplied },
            { state: RootState }
        >(
            `${NAME}/applyFilters`,
            async ({ skipGettingProjects, filters }, thunkAPI) => {
                await thunkAPI.dispatch(
                    extraActions.setFilters({ skipGettingProjects, filters })
                );
                if (!skipGettingProjects) {
                    await thunkAPI.dispatch(extraActions.getAllProjects());
                }
            }
        );
    }

    function getAllFiles() {
        return createAsyncThunk<any, string, { state: RootState }>(
            `${NAME}/getAllFiles`,
            async (id, thunkAPI) => {
                try {
                    const response = await apiClient.post<void>(
                        `${API_ENDPOINTS.project}/files/${id}`,
                        { id }
                    );

                    if (response.status === 201) {
                        return response.data;
                    } else {
                        throw new Error("Can't process query to external API");
                    }
                } catch (error: any) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error;
                }
            }
        );
    }

    function sendProject() {
        return createAsyncThunk<Project, ProjectDto, { state: RootState }>(
            `${NAME}/sendProject`,
            async (data, thunkAPI) => {
                try {
                    const response = await apiClient.post<Project>(
                        API_ENDPOINTS.project,
                        data
                    );
                    if (response.status === 201) {
                        if (response.data.status === ProjectStatuses.MODELLING || response.data.status === ProjectStatuses.READY_FOR_ASSIGNMENT) {
                            thunkAPI.dispatch(
                                alertActions.setMessage({
                                    alert: { type: "success", message: "ProjectCreated" },
                                })
                            );
                        } else {
                            thunkAPI.dispatch(
                                alertActions.setMessage({
                                    alert: { type: "warning", message: "ProjectCreatedWithoutEmail" },
                                })
                            );
                        }
                        return response.data;
                    } else {
                        throw new Error("cant process query to external API");
                    }
                } catch (error: any) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error;
                }
            }
        );
    }

    function getAllProjects() {
        return createAsyncThunk<
            any,
            ProjectFiltersApplied | void,
            { state: RootState }
        >(
            `${NAME}/getAllProjects`,
            async (filters: ProjectFiltersApplied | void, thunkAPI) => {
                const state = thunkAPI.getState();
                let stateFilters: ProjectFiltersApplied = state.project.appliedFilters;
                let requestConfig = filters ? filters : stateFilters;
                try {
                    const response = await apiClient.post<Project[]>(
                        `${API_ENDPOINTS.project}/getFiltered`,
                        requestConfig
                    );

                    if (response.status === 200) {
                        return response.data;
                    } else {
                        throw new Error("Cannot process query to external API");
                    }
                } catch (error) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error; // Rethrow the error to be handled by the calling code
                }
            }
        );
    }

    function getDiscSpace() {
        return createAsyncThunk<any, void, { state: RootState }>(
            `space-manager/getDiscSpace`,
            async (_, thunkAPI) => {
                try {
                    const response = await apiClient.get<any>(
                        `${API_ENDPOINTS.systemMonitor}/discSpace`
                    );
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        throw new Error("Can't process query to external API");
                    }
                } catch (error: any) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error;
                }
            }
        );
    }

    function archiveBy() {
        return createAsyncThunk<any, ArchiveBy, { state: RootState }>(
            `space-manager/archive`,
            async (data, thunkAPI) => {
                try {
                    const response = await apiClient.post<any>(
                        `${API_ENDPOINTS.systemMonitor}/archive`,
                        data
                    );
                    if (response.status === 200) {
                        thunkAPI.dispatch(extraActions.getDiscSpace())
                        thunkAPI.dispatch(
                            alertActions.setMessage({
                                alert: { type: "success", message: "Archived" },
                            })
                        );
                        return response.data;
                    } else {
                        throw new Error("cant process query to external API");
                    }
                } catch (error: any) {
                    thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
                    throw error;
                }
            }
        );
    }
}