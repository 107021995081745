import {
  createAsyncThunk,
  createSlice,
  createAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";

import { apiClient } from "../_helpers";
import {
  AlertType
} from "../_types";
import { RootState, alertActions } from "../_store";
import { history } from '../_helpers';
import { API_ENDPOINTS } from "../_constants";
import { jwtDecode } from "jwt-decode";
import { AxiosResponse } from "axios";
import { SessionValidation } from "../_constants/sessionValidation";
import { CustomJWT } from "../_types/CustomJWT";
import { generateAlert } from "../_helpers/alertGeneration.helper";
import Cookies from 'universal-cookie';
// create slice

const name = "login";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers: {}, extraReducers });

// exports

export const loginActions = { ...slice.actions, ...extraActions };
export const loginReducer = slice.reducer;

// implementation
interface LoginSlice {
  alert: {
    type: AlertType;
    message: string | undefined;
  } | null;
  JWTtoken: CustomJWT | undefined;
  isSessionValid: SessionValidation;
  loaders: {
    logInLoading: boolean;
  };
}

function createInitialState() {
  const initialState: LoginSlice = {
    alert: null,
    isSessionValid: SessionValidation.UNKNOWN,
    JWTtoken: undefined,
    loaders: {
      logInLoading: false,
    },
  };
  return initialState;
}
function createExtraActions() {
  return {
    hideAlert: hideAlert(),
    logIn: logIn(),
    validateSession: validateSession(),
    terminateSession: terminateSession(),
  };

  function terminateSession() {
    return createAsyncThunk<
    void,
    void,
    { state: RootState }
  >(
    `${name}/terminateSession`,
    async (__, thunkAPI) => {
      localStorage.clear()
      if(history.location.pathname!=='/login') {        
        const cookies = new Cookies();
        cookies.remove('user');
        history.navigate('login')
        window.location.reload()
      } 
    }
  );
  }

  function validateSession() {
    return createAsyncThunk<
      {
        accessToken?: CustomJWT,
        isSessionValid: SessionValidation,
      },
      void,
      { state: RootState }
    >(
      `${name}/validateSession`, // Add backend token validation
      async (_, thunkAPI) => {
        const user = localStorage.getItem('user')
        if (user) {
          const decoded = jwtDecode(user);
          if(decoded.exp && decoded.exp <= Number(new Date())/1000) {
            await thunkAPI.dispatch(loginActions.terminateSession())
            return {
              isSessionValid: SessionValidation.INVALID,
            }
          }
          return {
            accessToken: decoded as CustomJWT,
            isSessionValid: SessionValidation.VALID,
          }
        } else {
          await thunkAPI.dispatch(loginActions.terminateSession())
          return {
            isSessionValid: SessionValidation.INVALID,
          }
        }
      }
    );
  }

  function hideAlert() {
    return createAction(
      `${name}/hideAlert`
    );
  }

  function logIn() {
    return createAsyncThunk<
      {
        accessToken?: CustomJWT,
        isSessionValid: SessionValidation,
        encodedAccessToken: string | null
      },
      { username: string, password: string },
      { state: RootState }
    >(`${name}/logIn`, async (login_data, thunkAPI) => {
      const state = thunkAPI.getState();
      try {
        const response = await apiClient.post<string, AxiosResponse<{ access_token: string }>>(
          API_ENDPOINTS.auth + "/login",
          login_data
        );
        if (response.status === 200) {       
          const decoded = jwtDecode(response.data.access_token);
          return {
            accessToken: decoded as CustomJWT,
            isSessionValid: SessionValidation.VALID,
            encodedAccessToken: response.data.access_token
          };
        } else {
          throw new Error("Cannot add user to external API");
        }
      } catch (error: any) {
        thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
        throw error;
      }
    });
  }
}

function createExtraReducers() {
  return (builder: ActionReducerMapBuilder<typeof initialState>) => {
    logIn();
    hideAlert();
    validateSession();

    function hideAlert() {
      builder
        .addCase(extraActions.hideAlert, (state, action) => {
          state.alert = null
        })
    }

    function validateSession() {
      var { pending, fulfilled, rejected } = extraActions.validateSession;
      builder
        .addCase(pending, (state, action) => {
        })
        .addCase(fulfilled, (state, action) => {
          state.JWTtoken = action.payload.accessToken
          state.isSessionValid = action.payload.isSessionValid
        })
        .addCase(rejected, (state, action) => {

        });
    }

    function logIn() {
      var { pending, fulfilled, rejected } = extraActions.logIn;
      builder
        .addCase(pending, (state, action) => {
          state.loaders.logInLoading = true;
        })
        .addCase(fulfilled, (state, action) => {
          // Access the response data here
          state.loaders.logInLoading = false;

          // Assuming ac tion.payload is an object with the JWTtoken property
          state.JWTtoken = action.payload.accessToken;
          if (action.payload.encodedAccessToken) {
            const cookies = new Cookies();
            localStorage.setItem('user', action.payload.encodedAccessToken)
            cookies.set('user', action.payload.encodedAccessToken, { path: '/', expires: new Date((action.payload.accessToken?.exp || 0) * 1000 )});
            window.location.reload()
          }
          // Additional handling if needed
        })
        .addCase(rejected, (state, action) => {
          state.loaders.logInLoading = false;
        });
    }
  };
}

