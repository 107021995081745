import { t } from "i18next";

export function passwordValidator(password: string): string{

    if(password.length < 8){
        return t("ChangePassword.Password must be at least 8 characters long");
    }

    if (!/[A-Z]/.test(password)) {
        return t("ChangePassword.Password must contain at least one uppercase letter");
    }

    if (!/[a-z]/.test(password)) {
        return t("ChangePassword.Password must contain at least one lowercase letter");
    }

    if (!/\d/.test(password)) {
        return t("ChangePassword.Password must contain at least one digit");
    }

    return ''
}