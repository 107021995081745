import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import {
  useAppDispatch,
  emailsActions,
} from "../../_store";

import { useTranslation } from "react-i18next";
import { Card, CardActions, CardContent } from "@mui/material";
import { emailValidator } from "../../_helpers/validators.helper";

export function CreateEmail() {
  const dispatch = useAppDispatch();
  const [email, setSelectedOption] = useState("");
  const [errors, setErrors] = useState({
    email: false,
  });

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(emailsActions.getAllEmails());
  }, [dispatch]);

  const handleSubmitEmail = async () => {
    const validationErrors = {
      email: !email.trim() || !emailValidator(email),
    };

    if (!Object.values(validationErrors).some((error) => error)) {
      dispatch(
        emailsActions.addEmail({
          email: email,
        })
      );
    }

    setErrors(validationErrors);
  };

  return (
    <Card sx={{ width: "100%", display: "flex" }}>
      <CardContent sx={{ flex: 1 }}>
        <FormControl fullWidth>
          <TextField
            name="email"
            label={t("common.Enter email")}
            variant="outlined"
            size="small"
            value={email}
            error={errors.email}
            helperText={errors.email && t("common.This field is required")}
            onChange={(e) => setSelectedOption(e.target.value)}
          />
        </FormControl>
      </CardContent>
      <CardActions sx={{padding: "16px 16px"}}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{alignSelf: "flex-start"}}
          onClick={() => handleSubmitEmail()}
        >
          {t("common.Add email")}
        </Button>
      </CardActions>
    </Card>
  );
}
