import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import { useState } from 'react';

export function CopyComponent({ buffer }: { buffer: string }) {
    const [isCopied, setIsCopied] = useState(false);

    return (
        <IconButton
            aria-label="delete"
            size="small"
            onClick={() => {
                navigator.clipboard.writeText(buffer);
                setIsCopied(true)
                setTimeout(setIsCopied, 1000, false)
            }}
        >
            <>
                {isCopied ?
                    <CheckIcon fontSize="inherit" />
                    :
                    <ContentCopyIcon fontSize="inherit" />
                }
            </>
        </IconButton>
    )
}