import { AlertType } from "../_types";

const errorMessagePool = {
    "unknown": "unknown error",
    "create error: SQLITE_CONSTRAINT: UNIQUE constraint failed: project.externalId.": "Project id already exists",
    "ERR_NETWORK": "Network Error",
    "Unauthorized": "Request failed with status code 401",
    "create error: squareAmount are required for the done status.": "Please set the Square amount before confirming the project!",
    "create error: Processor is required for the done status.": "Please select processing person!",
    "Archiving process failed": "Archiving process failed",
    "Error: No projects found older than the specified months": "No projects found older than the specified months"
}

export function generateAlert(err: any) {
    const alert = {
        type: "error" as AlertType,
        message: errorMessagePool["unknown"],
    };
    if (err?.response?.data?.message && err?.response?.data?.message in errorMessagePool) {
        alert.message = errorMessagePool[err.response.data.message as keyof typeof errorMessagePool]
    }
    if (err.code && err.code in errorMessagePool) {
        alert.message = errorMessagePool[err.code as keyof typeof errorMessagePool]
    }
    return { alert }
}