import {
  createSlice,
} from "@reduxjs/toolkit";

import { extraReducers, initialState } from "./project.reducer";
import { extraActions } from "./project.actions";

export const NAME = "project";

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers,
});
// exports

export const projectActions = { ...slice.actions, ...extraActions };
export const projectReducer = slice.reducer;