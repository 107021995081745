import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import GroupIcon from '@mui/icons-material/Group';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTranslation } from 'react-i18next';
import { history } from '../../_helpers';
import { useAppSelector } from '../../_store';
import { UsersRoles } from '../../_constants';
import HelpIcon from '@mui/icons-material/Help';
import { DiskPanel } from './DiskPanel';
import React from 'react';
import DiscSpaceComponent from '../DiscSpaceComponent';

interface Props {
    additionalDrawerContent?: JSX.Element
}
export function MainDrawer(props: Props) {
    const { t } = useTranslation();
    const userRole = useAppSelector((store) => store.login.JWTtoken?.role)

    const [addModalIsOpen, setAddModalIsOpen] = React.useState(false);
    const [diskCulcIsLoading, setDiskCulcIsLoading] = React.useState(false);

    const diskPanel = (<>
        <DiskPanel
          open={addModalIsOpen}
          onClose={() => setAddModalIsOpen(false)}
          hendlLoadingDiskCulc={(isLoading: boolean) => hendlLoading(isLoading)}
        />
      </>)

    const hendlLoading = (isLoading: boolean) => {
        setDiskCulcIsLoading(isLoading)
    }

    return (
        <div>
            <Divider />
            <List>
                {(userRole === UsersRoles.ADMIN || userRole === UsersRoles.OPERATOR) &&
                    <ListItem disablePadding>
                        <ListItemButton selected={history.location.pathname === '/addproject'} href='/addproject'>
                            <ListItemIcon>
                                <ControlPointIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("sideMenu.Add project")} />
                        </ListItemButton>
                    </ListItem>
                }
                <ListItem disablePadding>
                    <ListItemButton selected={history.location.pathname === '/project-management'} href='/project-management'>
                        <ListItemIcon>
                            <FormatListBulletedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("sideMenu.List of projects")} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton selected={history.location.pathname === '/project-export'} href='/project-export'>
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("sideMenu.Projects data export")} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            {(userRole === UsersRoles.ADMIN) &&
                <>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton selected={history.location.pathname === '/user-management'} href='/user-management'>
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary={t("sideMenu.User management")} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton selected={history.location.pathname === '/modeling-emails-management'} href='/modeling-emails-management'>
                                <ListItemIcon>
                                    <AlternateEmailIcon />
                                </ListItemIcon>
                                <ListItemText primary={t("sideMenu.Modeling emails management")} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton selected={history.location.pathname === '/info'} href='/info'>
                            <ListItemIcon>
                                <HelpIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("sideMenu.Info")} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{
                        position: 'absolute',
                        bottom: '24px',
                    }}>
                        <ListItemButton disabled={diskCulcIsLoading} onClick={() => {
                                setAddModalIsOpen(true)
                            }}>
                            <DiscSpaceComponent diskCulcIsLoading={diskCulcIsLoading}/>
                        </ListItemButton>
                    </ListItem>
                </>
            }
            <List>
                {props.additionalDrawerContent}
            </List>

            {diskPanel}

        </div>
    );
}