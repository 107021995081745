import { useAppSelector, projectActions, useAppDispatch } from "../../_store";
import { GridColDef } from '@mui/x-data-grid';
import {Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', },
    { field: 'name', headerName: 'Name', flex: 5 },
    { field: 'status', headerName: 'Status', flex: 1 },
];

export function ProjectDataExportBar() {
    const dispatch = useAppDispatch();
    const exportInProgress = useAppSelector((store) => store.project.loaders.exportInProgress);
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">
                    {t("ProjectExport.Instruction")}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    {t("ProjectExport.To generate a project file you need:")}
                    <ul>
                        <li>
                            {t("ProjectExport.Select start date (Date on the left side) and end date (on the right side)")}
                        </li>
                        <li>
                            {t("ProjectExport.Projects are displayed including selected dates")}
                        </li>
                        <li>
                            {t("ProjectExport.The starting date must be less than the final date")}
                        </li>
                        <li>
                            {t("ProjectExport.You can also select other filters as you would on the project page")}
                        </li>
                        <li>
                            {t("ProjectExport.After selecting all the settings, click the download button and an excel file will be downloaded to your computer")}
                        </li>
                    </ul>
                </Typography>
            </CardContent>
            <CardActions sx={{padding: "16px 16px", justifyContent: "right"}}>
                <Button
                    variant="contained"
                    disabled={exportInProgress}
                    onClick={()=>{
                        dispatch(projectActions.exportProjectsData())
                    }}
                >
                    {t("ProjectExport.Export Data")}
                </Button>
            </CardActions>
        </Card>
    );
}
