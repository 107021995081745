import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../_store";
import { GridColDef } from '@mui/x-data-grid';
import { Modal as BaseModal } from "@mui/base/Modal";
import { IconButton, List, ListItem, css, styled } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Paper from '@mui/material/Paper';

import React from "react";
import { Project } from "../../_types";
import { useTranslation } from "react-i18next";

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', },
    { field: 'name', headerName: 'Name', flex: 5 },
    { field: 'status', headerName: 'Status', flex: 1 },
];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

export function ProjectImages() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);

    const { t } = useTranslation();

    const handleOpen = (index: number) => {
        setLightboxIndex(index);
        setOpen(true);
    };

    const files = useAppSelector((store) => store.project.current_files);
    const imgShownList = useAppSelector((store) => store.project.imgs_for_shown);

    const imgForShowList = imgShownList.map((imageFile, index) => (
        <ListItem 
        key={index}
        sx={{
            paddingLeft: '0'
        }}
        >
            {imageFile && (
                // Render a clickable image
                <img
                    src={imageFile}
                    alt={`Image ${index + 1}`}
                    onClick={() => handleOpen(index)}
                    style={{
                        cursor: "pointer",
                        width: "20%",
                        height: "auto",
                    }}
                />
            )}
        </ListItem>
    ));

    const grey = {
        50: "#F3F6F9",
        100: "#E5EAF2",
        200: "#DAE2ED",
        300: "#C7D0DD",
        400: "#B0B8C4",
        500: "#9DA8B7",
        600: "#6B7A90",
        700: "#434D5B",
        800: "#303740",
        900: "#1C2025",
    };

    const Modal = styled(BaseModal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

    const ModalContent = styled("div")(
        ({ theme }) => css`
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 500;
      text-align: start;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;
      background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border-radius: 8px;


      & .modal-title {
        margin: 0;
        line-height: 1.5rem;
        margin-bottom: 8px;
      }

      & .modal-description {
        margin: 0;
        line-height: 1.5rem;
        font-weight: 400;
        color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
        margin-bottom: 4px;
      }
    `
    );

    return (
        <>
            {imgForShowList.length > 0 && (
                <List>
                    {imgForShowList}
                </List>
            )}
            {/* Lightbox */}
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={open}
                onClose={() => { setOpen(false) }}
            // slots={{ backdrop: StyledBackdrop }}
            >
                <ModalContent
                    sx={{
                        margin: "auto", // Center the modal
                        overflow: "hidden", // Hide the scrollbar
                        display: "flex",
                        flexDirection: "column",
                        position: "relative", // Set position to relative for absolute positioning of the close button
                    }}
                >
                    <IconButton
                        edge="end"
                        onClick={() => { setOpen(false) }}
                        sx={{
                            position: "absolute",
                            top: "-7px",
                            right: "5px",
                            background: "none",
                            color: "#7d837c",
                            "&:hover": {
                                color: "#d0d9cf", // Change the color on hover
                                background: "none",
                            },
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                    <img
                        className="fit-picture"
                        src={imgShownList[lightboxIndex]}
                        alt="Grapefruit slice atop a pile of other slices"
                        style={{
                            maxHeight: "90vh", // Set the max height of the image to 90% of the viewport height
                        }}
                    />
                </ModalContent>
            </Modal>
        </>
    );
}
