import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../_store";
import { Box, Card, CardActions, CardContent, CardHeader, Divider, Fade, Grid, LinearProgress, Typography, createTheme } from "@mui/material";
import { Project } from "../../_types";
import { ProjectFiles } from "./ProjectFiles";
import { ProjectImages } from "./ProjectImages";
import { ProjectActions } from "./ProjectActions";
import { ProjectSquareAmount } from "./ProjectSquareAmount";
import { useTranslation } from "react-i18next";
import { DoneFiles } from "./DoneFiles";
import { CopyComponent } from "../CopyComponent";
import { ProjectStatusesColors } from "../../_constants";
import { themeConfig } from "../template/createCentralizedTheme";

export function ProjectsCard() {
    const selectedProject: Project = useAppSelector((store) => store.project.selectedProject);
    const selectedProjectIsLoading = useAppSelector((store) => store.project.loaders.selectedProjectIsLoading);
    
    const alertHeight = useAppSelector((state) => state.alert.alertHeight);
    const { t } = useTranslation();

    const [animationIsGoing, setAnimationIsGoing] = useState<boolean[]>([false, false]);
    const cardActionsRef: MutableRefObject<HTMLElement | undefined> = useRef()
    const cardHeaderRef: MutableRefObject<HTMLElement | undefined> = useRef()
    const theme = createTheme(themeConfig());
    const miniDrawerTransition = theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
    return (
        <Card>
            {selectedProject ?
                <>
                    <Fade
                        in={selectedProjectIsLoading && !animationIsGoing[0]}
                        onEntering={() => { setAnimationIsGoing([false, true]) }}
                        onExited={() => { setAnimationIsGoing([false, false]) }}
                        unmountOnExit
                    >
                        <CardContent>
                            <LinearProgress color="primary" />
                        </CardContent>
                    </Fade>

                    <Fade
                        in={!selectedProjectIsLoading && !animationIsGoing[1]}
                        onEntering={() => { setAnimationIsGoing([true, false]) }}
                        onExited={() => { setAnimationIsGoing([false, false]) }}
                        unmountOnExit
                    >
                        <span>
                            <CardHeader
                                ref={cardHeaderRef}
                                title={t(`ProjectCard.Project Details`)}
                                sx={{ 
                                    "& .MuiTypography-root": {
                                        fontSize: 16,
                                        fontWeight: 600,
                                        color: 'rgba(0, 0, 0, 0.87)'
                                    },
                                    maxHeight: '56px', 
                                    minHeight: '56px', 
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                                }}
                            />
                            <CardContent sx={{ transition: miniDrawerTransition, minHeight: 450, height: `calc(100vh - 240px${cardActionsRef.current?.offsetHeight && cardHeaderRef.current?.offsetHeight ? ` - ${cardActionsRef.current.offsetHeight + cardHeaderRef.current?.offsetHeight + (alertHeight || 0)}px` : ''})`, overflowY: "auto" }}>
                                <ProjectImages />
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <strong>{t(`ProjectCard.Name`)}: </strong>
                                            {selectedProject.name}
                                            {selectedProject.name && <CopyComponent buffer={selectedProject.name} />}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <strong>{t('ProjectCard.Address')}: </strong>
                                            {selectedProject.address}
                                            {selectedProject.address && <CopyComponent buffer={selectedProject.address} />}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <strong>{t('ProjectCard.GPS')}: </strong>
                                            {selectedProject.gps}
                                            {selectedProject.gps && <CopyComponent buffer={selectedProject.gps} />}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <ProjectSquareAmount />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <strong>{t('ProjectCard.Status')}: </strong>
                                            <span style={{ color: ProjectStatusesColors[selectedProject.status as keyof typeof ProjectStatusesColors] }}>
                                                {t(`common.projectStatus.${selectedProject.status}`)}
                                            </span>
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <strong>{t('ProjectCard.Modeling email')}: </strong> {selectedProject.processingEmail?.email || '---'}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <strong>{t('ProjectCard.Assignee')}: </strong> {selectedProject.processedBy?.userName || '---'}
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <strong>{t('ProjectCard.Sender')}: </strong> {selectedProject.addedBy?.userName || '---'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {selectedProject.createdAt &&
                                            <Typography variant="subtitle1" color="text.secondary">
                                                <strong>{t('ProjectCard.Date')}: </strong>
                                                {new Date(selectedProject.createdAt).toLocaleDateString()}
                                            </Typography>
                                        }
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <strong>{t('ProjectCard.Map link')}: </strong>
                                            <a href={`https://en.mapy.cz/zakladni?m3d=1&q=${selectedProject.address}`} target="_blank">
                                                {t('ProjectCard.Search at Map.cz')}
                                            </a>
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            <strong>{t('ProjectCard.Notes')}: </strong> {selectedProject.notesForProcessing}
                                        </Typography>
                                        {selectedProject.problemDescription &&
                                            <Typography variant="subtitle1" color="text.secondary">
                                                <strong>{t('ProjectCard.Problem description')}: </strong> {selectedProject.problemDescription}
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ProjectFiles />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DoneFiles />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions ref={cardActionsRef} sx={{ padding: 2, borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                                <Grid item xs={12}>
                                    <ProjectActions />
                                </Grid>
                            </CardActions>
                        </span>
                    </Fade>
                </>
                :
                <CardContent>
                    <Typography variant="subtitle1" color="text.secondary">
                        <strong>{t("ProjectCard.Waiting for selected project...")}</strong>
                    </Typography>
                </CardContent>
            }
        </Card>
    );
}
