import { useEffect } from "react";
import { useAppSelector, projectActions, useAppDispatch } from "../../../_store";
import { DataGrid, GridColDef, GridEventListener, GridRowParams, GridSortItem } from '@mui/x-data-grid';
import { Card, createTheme } from "@mui/material";
import { ProjectStatuses, ProjectStatusesColors } from "../../../_constants/ProjectStatuses";
import { useTranslation } from "react-i18next";
import { GetDataGridLocalization } from "../../../_localizations/ComponetsLocalizations.helper";
import { ProcessedByCell } from "./ProcessedByCell";
import { UsersRoles } from "../../../_constants";
import { themeConfig } from "../../template/createCentralizedTheme";

const getRowClassName = (params: GridRowParams, userRole?: string) => {
  let resultClass = ''
  if (params.row.isUrgent) {
    if (userRole === UsersRoles.OPERATOR) {
      if (params.row.status !== ProjectStatuses.ARCHIVATED) {
        resultClass += ' urgent-row'
      }
    } else {
      if (params.row.status !== ProjectStatuses.ARCHIVATED && params.row.status !== ProjectStatuses.DONE) {
        resultClass += ' urgent-row'
      }
    }
  }
  if (params.row.status === ProjectStatuses.PROBLEM) {
    resultClass += ' problem-row'
  }
  return resultClass;
};

export function ProjectsTable() {
  const { t } = useTranslation();
  const DataGridLocale = GetDataGridLocalization()
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((store) => store.login.JWTtoken?.role)
  const projectsList = useAppSelector((store) => store.project.projectList);
  const alertHeight = useAppSelector((state) => state.alert.alertHeight);
  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    dispatch(projectActions.selectProject(params.row.id))
    dispatch(projectActions.getAllFiles(params.row.id));
  };
  useEffect(() => {
    dispatch(projectActions.getAllProjects());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', },
    {
      field: 'name', headerName: t(`ProjectCard.Name`), flex: 4, headerClassName: 'ml20', cellClassName: "customStatusColor", renderCell: (params) =>
      (<>
        <div style={{
          backgroundColor: ProjectStatusesColors[params.row.status as keyof typeof ProjectStatusesColors],
          width: '10px',
          height: '100%',
          marginRight: '10px',
          flexShrink: 0
        }}> </div>
        <span>{params.value}</span>
      </>)
    },
    {
      field: 'status', headerName: t(`ProjectCard.Status`), flex: 2, renderCell: (params) => {
        return (
          <span style={{ color: ProjectStatusesColors[params.value as keyof typeof ProjectStatusesColors] }}>
            {t(`common.projectStatus.${params.value}`)}
          </span>
        )
      }
    },
    {
      field: 'processedBy', headerName: t(`ProjectsTable.Processed By`), flex: 2, renderCell: (params) => {
        return (
          <ProcessedByCell cellParams={params} />
        )
      },
    },
    {
      field: 'isUrgent',
      headerName: 'Urgent',
      valueGetter: (params) => params.row,
      sortComparator: (v1, v2) => {
        let urgent1 = v1.isUrgent
        let urgent2 = v2.isUrgent
        if (v1.status === ProjectStatuses.ARCHIVATED || (v1.status === ProjectStatuses.DONE && userRole !== UsersRoles.OPERATOR)) {
          urgent1 = false
        }
        if (v2.status === ProjectStatuses.ARCHIVATED || (v2.status === ProjectStatuses.DONE && userRole !== UsersRoles.OPERATOR)) {
          urgent2 = false
        }
        if (!urgent1 && urgent2) {
          return -1
        }
        if (urgent1 && !urgent2) {
          return 1
        }        
        return 0
      }
    },
  ];

  const sortModel: GridSortItem[] = [
    {
      field: 'isUrgent',
      sort: 'desc',
    },
  ];
  
  const theme = createTheme(themeConfig());
  const miniDrawerTransition = theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })
  return (
    <Card sx={{ transition: miniDrawerTransition, minHeight: 600, height: `calc(100vh - 240px - ${alertHeight || 0}px)`, width: '100%' }}>
      <DataGrid
        disableColumnMenu
        localeText={DataGridLocale}
        onRowClick={handleRowClick}
        columnVisibilityModel={{
          id: false,
          isUrgent: false,
          processedBy: userRole === UsersRoles.ADMIN || userRole === UsersRoles.MANAGER
        }}
        rows={projectsList}
        columns={columns}
        getRowClassName={(params) => {
          return getRowClassName(params, userRole)
        }}
        sortModel={sortModel}
      />
    </Card>
  );
}