import {
  createAsyncThunk,
  createSlice,
  ActionReducerMapBuilder,
  createAction,
} from "@reduxjs/toolkit";

import { apiClient } from "../_helpers";
import { AlertType, EmailFrontDto } from "../_types";
import { RootState } from ".";
import { API_ENDPOINTS } from "../_constants";
import { alertActions } from "../_store";
import { generateAlert } from "../_helpers/alertGeneration.helper";

const name = "emails";
const initialState = createInitialState();

const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers,
});

const extraActions = createExtraActions();
// exports

export const emailsActions = { ...slice.actions, ...extraActions };
export const emailsReducer = slice.reducer;

// implementation
interface EmailsSlice {
  emails: any[];
  loaders: {
    emailListIsLoading: boolean;
    emailIsAdding: boolean;
    emailIsDeleting: boolean;
  };
  alert: {
    type: AlertType;
    message: string;
  } | null;
}

function createInitialState() {
  const initialState: EmailsSlice = {
    emails: [],
    loaders: {
      emailListIsLoading: true,
      emailIsAdding: false,
      emailIsDeleting: false,
    },
    alert: null,
  };
  return initialState;
}

function createExtraActions() {
  return {
    getAllEmails: getAllEmails(),
    deleteEmail: deleteEmail(),
    addEmail: addEmail(),
    hideAlert: hideAlert(),
  };

  function getAllEmails() {
    return createAsyncThunk<EmailFrontDto[], void, { state: RootState }>(
      `${name}/getAllEmails`,
      async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        try {
          const response = await apiClient.get<EmailFrontDto[]>(
            API_ENDPOINTS.emails
          );

          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error("Cannot process query to external API");
          }
        } catch (error: any) {
          thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
          throw error;
        }
      }
    );
  }

  function addEmail() {
    return createAsyncThunk<void, EmailFrontDto, { state: RootState }>(
      `${name}/addEmail`,
      async (newEmail, thunkAPI) => {
        try {
          const state = thunkAPI.getState();
          const response = await apiClient.post<EmailFrontDto>(
            API_ENDPOINTS.emails,
            newEmail
          );

          if (response.status === 201) {
            thunkAPI.dispatch(alertActions.setMessage({ alert: { type: "success", message: "EmailCreated" } }));
            thunkAPI.dispatch(emailsActions.getAllEmails());
          } else {
            throw new Error("Cannot add email to external API");
          }
        } catch (error: any) {
          thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
          throw error;
        }
      }
    );
  }

  function deleteEmail() {
    return createAsyncThunk<void, number, { state: RootState }>(
      `${name}/deleteEmail`,
      async (emailId, thunkAPI) => {
        try {
          const response = await apiClient.delete(
            `${API_ENDPOINTS.emails}/${emailId}`
          );

          if (response.status === 200) {
            thunkAPI.dispatch(alertActions.setMessage({ alert: { type: "success", message: "EmailDeleted" } }));
            thunkAPI.dispatch(emailsActions.getAllEmails());
          } else {
            throw new Error("Cannot delete email from external API");
          }
        } catch (error: any) {
          thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
          throw error;
        }
      }
    );
  }

  function hideAlert() {
    return createAction(`${name}/hideAlert`);
  }
}

function createExtraReducers() {
  return (builder: ActionReducerMapBuilder<typeof initialState>) => {
    getAllEmails();
    deleteEmail();
    addEmail();
    hideAlert();

    function getAllEmails() {
      var { pending, fulfilled, rejected } = extraActions.getAllEmails;
      builder
        .addCase(pending, (state, action) => {
          state.loaders.emailListIsLoading = true;
        })
        .addCase(fulfilled, (state, action) => {
          state.emails = action.payload;
          state.loaders.emailListIsLoading = false;
        })
        .addCase(rejected, (state, action) => {
          state.loaders.emailListIsLoading = false;
        });
    }

    function deleteEmail() {
      var { pending, fulfilled, rejected } = extraActions.deleteEmail;
      builder
        .addCase(pending, (state, action) => {
          state.loaders.emailIsDeleting = true;
        })
        .addCase(fulfilled, (state, action) => {
          state.loaders.emailIsDeleting = false;
        })
        .addCase(rejected, (state, action) => {
          state.loaders.emailIsDeleting = false;
        });
    }

    function addEmail() {
      var { pending, fulfilled, rejected } = extraActions.addEmail;
      builder
        .addCase(pending, (state, action) => {
          state.loaders.emailIsAdding = true;
        })
        .addCase(fulfilled, (state, action) => {
          state.loaders.emailIsAdding = false;
        })
        .addCase(rejected, (state, action) => {
          state.loaders.emailIsAdding = false;
        });
    }

    function hideAlert() {
      builder.addCase(extraActions.hideAlert, (state, action) => {
        state.alert = null;
      });
    }
  };
}
