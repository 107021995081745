import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LanguageIcon from '@mui/icons-material/Language';

import { ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Button, Divider, Fade, FormControl, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, Select, colors, createTheme } from "@mui/material";
import { locales } from "../../_localizations/config";
import { themeConfig } from "./createCentralizedTheme";
import { loginActions, useAppDispatch } from "../../_store";
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect } from "react";
import { AlertComponent } from "../alertComponents/AlertComponent";
import PasswordIcon from '@mui/icons-material/Password';
import { ChangePassword } from './ChangePassword'
import Cookies from "universal-cookie";

const drawerWidth = 340;
const miniSectionWidth = 55;

interface Props {
  drawer: JSX.Element;
  children: JSX.Element;
  globalLoader?: boolean;
}

export function MainContainer(props: Props) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [addModalIsOpen, setAddModalIsOpen] = React.useState(false);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const handleMiniDrawerClose = () => {
    setMiniDrawerOpen(false)
    localStorage.setItem("miniDrawerOpen", "false")
  }
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniDrawerOpen, setMiniDrawerOpen] = React.useState(localStorage.getItem("miniDrawerOpen") === "false" ? false : true);
  const [darkMode, setDarkMode] = React.useState(
    localStorage.getItem("darkMode") === "true"
  );

  const theme = createTheme(themeConfig());
  const isDesktopDrawer = useMediaQuery(theme.breakpoints.up("md"));
  const handleDrawerToggle = () => {
    if (!miniDrawerOpen) {
      setMiniDrawerOpen(true)
      localStorage.setItem("miniDrawerOpen", "true")
    }
    if (!isDesktopDrawer) {
      setMobileOpen(!mobileOpen);
    }
  };
  const { t, i18n } = useTranslation()
  const [locale, setLocale] = React.useState<string>('en');

  useEffect(() => {
    let lang = localStorage.getItem("language")
    if (lang) {
      i18n.changeLanguage(lang)
      setLocale(lang)
    } else {
      setLocale('en')
    }
  }, [locale])
  const handleChangeLanguage = (event: any) => {
    setLocale(event.target.value);
    i18n.changeLanguage(event.target.value)
    localStorage.setItem('language', event.target.value)
  }
  const miniDrawerTransition = theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })
  const drawer = props.drawer;
  const dispatch = useAppDispatch();
  const ConfigMenu = (<div>
    <Button
      id="basic-button"
      aria-controls={menuOpen ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={menuOpen ? 'true' : undefined}
      onClick={handleMenuClick}
      variant="text"
      sx={{ color: 'white' }}
    >
      <SettingsIcon sx={{ marginRight: '.1em', color: 'white' }} /> {t("common.Settings")}
    </Button>
    <Menu
      id="basic-menu"
      anchorEl={menuAnchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem>
        <ListItemIcon>
          <LanguageIcon fontSize="small" />
        </ListItemIcon>
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <Select
            fullWidth
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={locale}
            onChange={handleChangeLanguage}
            label="Age"
          >
            {Object.keys(locales).map((keyName) => {
              let key = keyName as keyof typeof locales
              return (
                <MenuItem key={`lang-${keyName}`} value={keyName}>{locales[key]}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </MenuItem>
      <MenuItem onClick={() => {
        setDarkMode(!darkMode);
        localStorage.setItem("darkMode", darkMode.toString());
      }}>
        <ListItemIcon>
          {darkMode ? <DarkModeIcon fontSize="small" /> : <LightModeIcon fontSize="small" />}
        </ListItemIcon>
        <FormControlLabel
          control={
            <Switch
              checked={darkMode}
              onChange={(event, checked) => {
                setDarkMode(checked);
                localStorage.setItem("darkMode", checked.toString());
              }}
              name="gilad"
            />
          }
          label={t("common.Dark mode")}
        />
        {/*<ListItemText>Dark mode</ListItemText>*/}
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => {
        setAddModalIsOpen(true)
        setMenuAnchorEl(null)
      }}> {/* change password */}
        <ListItemIcon>
          <PasswordIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t("common.Change password")}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => {
        const cookies = new Cookies();
        cookies.remove('user');
        localStorage.clear()
        window.location.reload()
      }}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t("common.Logout")}</ListItemText>
      </MenuItem>
    </Menu>
  </div>)

  const changePassword = (<>
    <ChangePassword
      open={addModalIsOpen}
      onClose={() => setAddModalIsOpen(false)}
    />
  </>)

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${miniDrawerOpen ? drawerWidth : 0}px)` },
            ml: { md: `${miniDrawerOpen ? drawerWidth : 0}px` },
            transition: miniDrawerTransition
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: miniDrawerOpen ? "none" : 'block' } }}
            >
              <MenuIcon />
            </IconButton>
            <Grid container justifyContent="space-between">
              <Typography variant="h6" noWrap component="div">
                RoofsManager
              </Typography>
              {ConfigMenu}
              {changePassword}
            </Grid>
          </Toolbar>
          <Fade
            in={props.globalLoader}
            unmountOnExit
          >
            <LinearProgress color="primary" />
          </Fade>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { md: miniDrawerOpen ? drawerWidth : miniSectionWidth }, transition: miniDrawerTransition, flexShrink: { md: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                zIndex: 0,
              },
            }}
          >
            <Toolbar />
            {!isDesktopDrawer && drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: miniDrawerOpen ? drawerWidth : miniSectionWidth,
                zIndex: 0,
                overflowX: 'hidden',
                transition: miniDrawerTransition,
              },
            }}
            open
          >
            <Box sx={{ width: drawerWidth }}>
              <Toolbar sx={{justifyContent: "right"}}>
                <IconButton sx={{marginRight: -2}} onClick={handleMiniDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </Toolbar>  {/* desktop */}
              {isDesktopDrawer && drawer}
            </Box>
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { md: `calc(100% - ${miniDrawerOpen ? drawerWidth : miniSectionWidth}px)` },
            transition: miniDrawerTransition
          }}
        >
          <Toolbar />
          <AlertComponent />
          {props.children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
