import {
    Button,
    CardContent,
    Grid,
    LinearProgress,
    Typography,
    createTheme,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from "i18next";
import { useEffect, useState } from "react";
import { projectActions, useAppDispatch, useAppSelector } from "../_store";
import { readFileAsBuffer } from "../_helpers/fileProcessing.halper";
import { Project } from "../_types";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { getfileExtension } from "../_helpers";
import { ARCHIVE_EXTENSIONS } from "../_constants";
import { themeConfig } from "./template/createCentralizedTheme";

export function AddFiles({ files, setFiles, color, style, autoSand, clipboardPaste }:
    {
        files: File[],
        setFiles: Function,
        color?: "primary" | "secondary",
        style?: any,
        autoSand?: boolean,
        clipboardPaste?: boolean
    }) {

    const dispatch = useAppDispatch();
    const theme = createTheme(themeConfig());
    const selectedProject: Project = useAppSelector((store) => store.project.selectedProject);
    const selectedProjectIsLoading = useAppSelector((store) => store.project.loaders.selectedProjectIsLoading);
    const [isDragOver, setIsDragOver] = useState(false);
    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFiles = Array.from(e.target.files || []).filter(
            (file) => !files.some((existingFile) => existingFile.name === file.name)
        );

        if (autoSand) {
            autoSendHandler(newFiles)
        } else {
            setFiles([...files, ...newFiles]);
        }
    };

    const autoSendHandler = async (newFiles: File[]) => {
        const fileBuffers = await Promise.all(newFiles.map(readFileAsBuffer));
        const filenames = newFiles.map((file) => {
            return file.name;
        });

        await dispatch(
            projectActions.sendFiles({
                id: selectedProject.id,
                namesOfFiles: filenames,
                fileBufferArray: fileBuffers,
                done: true
            })
        );
        setFiles([])
    }

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = "copy";
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const droppedFiles = Array.from(e.dataTransfer.files);
        const newFiles = droppedFiles.filter(
            (file) => !files.some((existingFile) => existingFile.name === file.name)
        );

        if (autoSand) {
            autoSendHandler(newFiles)
        } else {
            setFiles([...files, ...newFiles]);
        }

        setIsDragOver(false);
    };

    const pasteImg = async () => {
        if (clipboardPaste) {
            try {
                const clipboardItems = await navigator.clipboard.read()
                const blobOutput = await clipboardItems[0].getType('image/png')
                setFiles([...files, new File([blobOutput], `screen.png`, { type: "image/png" })])
            } catch (e) {
                console.log(e);
            }
        }
    }
    useEffect(() => {
        if (clipboardPaste) {
            const handleKeyDown = (event: KeyboardEvent) => {
                const code = event.which || event.keyCode;

                let charCode = String.fromCharCode(code).toLowerCase();
                if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
                    pasteImg()
                }
            };
            window.addEventListener('keydown', handleKeyDown);
            return () => window.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const handleRemoveFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };
    return (
        <CardContent
            style={style}
            sx={{
                flex: 1,
                p: 2,
                border: isDragOver ? "2px solid #aaa" : "2px dashed #ccc"
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <Grid
                container
                spacing={2}
            >
                {files.map((file, index) => (
                    <Grid
                        key={index}
                        item
                        xs={12}
                    >
                        <Grid container
                            sx={{
                                border: '1px solid rgba(224, 224, 224, 1)',
                                borderRadius: 1,
                                py: 1
                            }}>
                            {file.type.includes('image') &&
                                <Grid item xs={12} sx={{
                                    px: 2,
                                    pb: 2,
                                    pt: 1,
                                    justifyContent: 'center',
                                    display: "flex"
                                }}
                                >
                                    <img style={{ maxWidth: '100%' }} src={URL.createObjectURL(file)} />
                                </Grid>}
                            <Grid item xs={10} sx={{ pl: 2, display: 'flex' }}>
                                {!file.type.includes('image') && <>
                                    {ARCHIVE_EXTENSIONS.includes(getfileExtension(file.name)) ?
                                        <FolderZipIcon color={color} sx={{ mr: 2 }} fontSize="large" />
                                        :
                                        <InsertDriveFileIcon color={color} sx={{ mr: 2 }} fontSize="large" />
                                    }
                                </>}
                                <Typography variant="subtitle1" sx={{ flex: 1, margin: 'auto' }}>
                                    {file.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{
                                pr: 2, justifyContent: 'end',
                                display: "flex"
                            }}>
                                <DeleteIcon
                                    color="error"
                                    onClick={() => handleRemoveFile(index)}
                                    sx={{ cursor: "pointer", my: "auto" }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                ))}
            </Grid>
            <label>
                <input
                    type="file"
                    accept="*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
                <Button
                    fullWidth
                    disabled={selectedProjectIsLoading}
                    color={color}
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mt: 2 }}
                >
                    {t("AddProject.Pick file")}
                </Button>
            </label>
        </CardContent>
    );
}