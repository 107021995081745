import { Project } from "../../_types";
import { useTranslation } from "react-i18next";
import { Box, IconButton, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import { useAppSelector, projectActions, useAppDispatch } from "../../_store";
import CircularProgress from '@mui/material/CircularProgress';
import { UsersRoles } from "../../_constants";

export function ProjectSquareAmount() {

    const { t } = useTranslation();
    const selectedProject: Project = useAppSelector((store) => store.project.selectedProject);
    const selectedProjectIsLoading = useAppSelector((store) => store.project.loaders.selectedProjectIsLoading);
    const userRole = useAppSelector((store) => store.login.JWTtoken?.role)
    const dispatch = useAppDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const [squareAmount, setSquareAmount] = useState(0);

    const handleSubmit = async () => {
        dispatch(projectActions.updateProject({ id: selectedProject.id, squareAmount: squareAmount }))
        setIsEditMode(false)
    }

    useEffect(() => {
        setSquareAmount(Number(selectedProject.squareAmount))
        setIsEditMode(false)
    }, [selectedProject]);

    return (
        <>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem'
            }}>
                <strong>{t('ProjectCard.Plohy')}: </strong>

                {isEditMode ?
                    <>
                        <TextField
                            id="squareAmountField"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            value={squareAmount}
                            onChange={(e) => setSquareAmount(Number(e.target.value))}
                        />
                        <IconButton aria-label="delete" size="small" disabled={selectedProjectIsLoading} onClick={handleSubmit}>
                            <SendIcon fontSize="inherit" />
                        </IconButton>
                        {selectedProjectIsLoading ?
                            <>
                                <CircularProgress />
                            </> : <></>
                        }
                    </>
                    :
                    <>
                        {!selectedProjectIsLoading &&
                            <>
                                {selectedProject.squareAmount}
                                {(userRole === UsersRoles.ADMIN || userRole === UsersRoles.MANAGER || userRole === UsersRoles.PROCESSOR) &&
                                    <IconButton aria-label="delete" size="small" onClick={() => { setIsEditMode(true) }}>
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            </>
                        }
                    </>
                }
            </Box>
        </>
    );
}
