import {
    ActionReducerMapBuilder,
} from "@reduxjs/toolkit";

import {
    AlertType,
    ProjectFiltersOprions,
    ProjectFiltersApplied,
} from "../../_types";
import { extraActions } from "./project.actions";
import { isImageFile } from "../../_helpers/fileProcessing.halper";

export const extraReducers = createExtraReducers();
export const initialState = createInitialState();
// implementation
interface ProjectSlice {
    projectList: any[];
    statistics?: {
        total: number
        processing: number
        readyForChecking: number
        done: number
        readyForAssignment: number
        modeling: number
    }
    selectedProject?: any;
    loaders: {
        selectedProjectIsLoading: boolean;
        projectIsAdding: boolean;
        listLoading: boolean;
        countIsLoading: boolean;
        bikeDetailsAreLoading: boolean;
        exportInProgress: boolean;
    };
    requestErrors?: Error | null;
    alert: {
        type: AlertType;
        message: string;
    } | null;
    appliedFilters: ProjectFiltersApplied;
    availableFilters?: ProjectFiltersOprions;
    current_files: string[];
    imgs_for_shown: string[];
    pathToDoneFile?: string[];
    rejected?: boolean;
    DiscSpace: {
        free: number;
        size: number
    };
    ArchiveBy: {
        month: number
    };
}

function createInitialState() {
    const initialState: ProjectSlice = {
        projectList: [],
        loaders: {
            selectedProjectIsLoading: false,
            projectIsAdding: false,
            listLoading: false,
            countIsLoading: false,
            bikeDetailsAreLoading: false,
            exportInProgress: false,
        },
        appliedFilters: {
            statuses: [],
            senders: [],
            assignee: [],
            searchString: "",
        },
        requestErrors: null,
        alert: null,
        current_files: [],
        imgs_for_shown: [],
        DiscSpace: {
            free: 0,
            size: 0
        },
        ArchiveBy: {
            month: 3
        }
    };
    let appliedFiltersString = localStorage.getItem("appliedFilters");
    if (appliedFiltersString) {
        let appliedFilters = JSON.parse(appliedFiltersString);
        if (appliedFilters) {
            initialState.appliedFilters = appliedFilters;
        }
    }
    return initialState;
}

function createExtraReducers() {
    return (builder: ActionReducerMapBuilder<typeof initialState>) => {
        getAllProjects();
        sendProject();
        getAllFiles();
        forceSendProjectLoader();
        hideAlert();
        selectProject();
        getFilters();
        updateProject();
        setFilters();
        exportProjectsData();
        sendFiles();
        deleteFile();
        getDiscSpace();
        archiveBy();

        function deleteFile() {
            var { pending, fulfilled, rejected } = extraActions.deleteFile;
            builder
                .addCase(pending, (state, action) => {
                    state.loaders.projectIsAdding = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.loaders.projectIsAdding = false
                    state.pathToDoneFile = action.payload.doneFileUrls
                    state.imgs_for_shown = action.payload.fileUrls.filter((file: string) =>
                        isImageFile(file)
                    );
                    state.current_files = action.payload.fileUrls.filter(
                        (file: string) => !isImageFile(file)
                    );
                    state.alert = {
                        type: "success",
                        message: "Created",
                    };
                })
                .addCase(rejected, (state, action) => {
                    state.loaders.projectIsAdding = false;
                });
        }

        function sendFiles() {
            var { pending, fulfilled, rejected } = extraActions.sendFiles;
            builder
                .addCase(pending, (state, action) => {
                    state.loaders.projectIsAdding = true
                    state.loaders.selectedProjectIsLoading = true
                })
                .addCase(fulfilled, (state, action) => {
                    state.loaders.projectIsAdding = false
                    state.loaders.selectedProjectIsLoading = false
                    state.pathToDoneFile = action.payload.doneFileUrls
                    state.imgs_for_shown = action.payload.fileUrls.filter((file: string) =>
                        isImageFile(file)
                    );
                    state.current_files = action.payload.fileUrls.filter(
                        (file: string) => !isImageFile(file)
                    );
                    state.alert = {
                        type: "success",
                        message: "Created",
                    };
                })
                .addCase(rejected, (state, action) => {
                    state.loaders.projectIsAdding = false
                    state.loaders.selectedProjectIsLoading = false
                    const msg = action.error.message
                        ? action.error.message
                        : "No message";
                    state.alert = {
                        type: "error",
                        message: msg,
                    };
                })
        }

        function exportProjectsData() {
            var { pending, fulfilled, rejected } = extraActions.exportProjectsData;
            builder
                .addCase(pending, (state, action) => {
                    state.loaders.exportInProgress = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.loaders.exportInProgress = false;
                })
                .addCase(rejected, (state, action) => {
                    state.loaders.exportInProgress = false;
                });
        }

        function updateProject() {
            var { pending, fulfilled, rejected } = extraActions.updateProject;
            builder
                .addCase(pending, (state, action) => {
                    state.loaders.selectedProjectIsLoading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.selectedProject = action.payload;
                    var foundIndex = state.projectList.findIndex(
                        (x) => x.id == action.payload.id
                    );
                    state.projectList[foundIndex] = action.payload;
                    state.loaders.selectedProjectIsLoading = false;
                })
                .addCase(rejected, (state, action) => {
                    state.loaders.selectedProjectIsLoading = false;
                });
        }

        function getFilters() {
            var { pending, fulfilled, rejected } = extraActions.getFilters;
            builder
                .addCase(pending, (state, action) => { })
                .addCase(fulfilled, (state, action) => {
                    state.availableFilters = action.payload;
                })
                .addCase(rejected, (state, action) => { });
        }

        function selectProject() {
            var { pending, fulfilled, rejected } = extraActions.selectProject;
            builder
                .addCase(pending, (state, action) => {
                    state.loaders.selectedProjectIsLoading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.selectedProject = action.payload;
                    var foundIndex = state.projectList.findIndex(
                        (x) => x.id == action.payload.id
                    );
                    state.projectList[foundIndex] = action.payload;
                    state.loaders.selectedProjectIsLoading = false;
                    state.loaders.selectedProjectIsLoading = false;
                })
                .addCase(rejected, (state, action) => {
                    state.loaders.selectedProjectIsLoading = false;
                });
        }

        function hideAlert() {
            builder.addCase(extraActions.hideAlert, (state, action) => {
                state.alert = null;
            });
        }

        function forceSendProjectLoader() {
            builder.addCase(extraActions.forceSendProjectLoader, (state, action) => {
                state.loaders.projectIsAdding = true;
            });
        }

        function getAllFiles() {
            var { pending, fulfilled, rejected } = extraActions.getAllFiles;
            builder
                .addCase(pending, (state, action) => { })
                .addCase(fulfilled, (state, action) => {
                    state.pathToDoneFile = action.payload.doneFileUrls
                    state.imgs_for_shown = action.payload.fileUrls.filter((file: string) =>
                        isImageFile(file)
                    );
                    state.current_files = action.payload.fileUrls.filter(
                        (file: string) => !isImageFile(file)
                    );
                })
                .addCase(rejected, (state, action) => {
                    state.imgs_for_shown = [];
                    state.current_files = [];
                    state.requestErrors = action.error as Error;
                });
        }

        function sendProject() {
            var { pending, fulfilled, rejected } = extraActions.sendProject;
            builder
                .addCase(pending, (state, action) => {
                    state.loaders.projectIsAdding = true;
                    state.rejected = undefined;
                })
                .addCase(fulfilled, (state, action) => {
                    state.loaders.projectIsAdding = false;
                    state.rejected = false;
                })
                .addCase(rejected, (state, action) => {
                    state.loaders.projectIsAdding = false;
                    state.rejected = true;
                });
        }

        function getAllProjects() {
            var { pending, fulfilled, rejected } = extraActions.getAllProjects;
            builder
                .addCase(pending, (state, action) => {
                    state.loaders.listLoading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    // Extract unique names using Set
                    state.loaders.listLoading = false;
                    state.requestErrors = undefined;
                    state.projectList = action.payload.projects;
                    state.statistics = action.payload.statistics;
                })
                .addCase(rejected, (state, action) => {
                    state.loaders.listLoading = false;
                    state.requestErrors = action.error as Error;
                });
        }

        function setFilters() {
            builder.addCase(extraActions.setFilters, (state, action) => {
                state.appliedFilters = action.payload.filters;
                if (!action.payload.skipGettingProjects) {
                    localStorage.setItem(
                        "appliedFilters",
                        JSON.stringify(action.payload.filters)
                    );
                }
            });
        }

        function getDiscSpace() {
            var { pending, fulfilled, rejected } = extraActions.getDiscSpace;
            builder
                .addCase(pending, (state, action) => {
                    state.loaders.listLoading = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.loaders.listLoading = false;
                    state.requestErrors = undefined;
                    state.DiscSpace = action.payload;
                })
                .addCase(rejected, (state, action) => {
                    state.loaders.listLoading = false;
                    state.requestErrors = action.error as Error;
                });
        }

        function archiveBy() {
            var { pending, fulfilled, rejected } = extraActions.archiveBy;
            builder
                .addCase(pending, (state, action) => {
                    state.loaders.projectIsAdding = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.loaders.projectIsAdding = false
                    state.ArchiveBy = action.payload
                })
                .addCase(rejected, (state, action) => {
                    state.loaders.projectIsAdding = false;
                });
        }
    };
}
