export const enTranslation = {
  sideMenu: {
    "List of projects": "List of projects",
    "Add project": "Add project",
    "Modeling emails management": "Modeling emails management",
    "User management": "User management",
    "Projects data export": "Projects data export",
    "Info": "Help"
  },
  common: {
    Send: "Send",
    projectStatus: {
      added: "Added",
      modelling: "Modelling",
      ready_for_assignment: "Waiting for assignment",
      processing: "Processing",
      readyforchecking: "Waiting for control",
      done: "Done",
      archivated: "Archived",
      problem: "Problem"
    },
    "total": "Total",
    "This field is required": "This field is required",
    "All Users": "All Users",
    Yes: "Yes",
    No: "No",
    Submit: "Submit",
    "Login": "Login",
    "Add email": "Add email",
    "Enter email": "Enter email",
    "Settings": "Settings",
    "Logout": "Logout",
    "Change password": "Change password",
    "Dark mode": "Dark mode",
  },
  ProjectActions: {
    "Unmark as Urgent": "Mark as not urgent",
    "Confirm project": "Confirm project",
    "All Files": "All Files",
    "Image Files": "Image Files",
    "Show the page of project": "Show the page of project",
    Archivate: "Archivate",
    "Mark as Urgent": "Mark as Urgent",
    "Report problem with the project": "Report problem with the project",
    "Resolve problem": "Resolve problem"
  },
  AddProject: {
    "Adjustment": "Adjustment",
    "Project is urgent": "Project is urgent",
    "Pick file": "Choose file",
    "Modeling emails are loading...": "Modeling emails are loading...",
    "Email for modelling": "Email for modelling",
    Name: "Name",
    Address: "Address",
    GPS: "GPS",
    "Notes for modelling": "Notes for modelling",
    "Notes for processing": "Notes for processing",
  },
  ProjectCard: {
    "Project Details": "Project Details",
    "For completed project": "Completed project",
    Name: "Name",
    Address: "Address",
    GPS: "GPS",
    Status: "Status",
    Plohy: "Square",
    "Modeling email": "Modeling email",
    Assignee: "Assignee",
    Sender: "Sender",
    Notes: "Notes",
    "Waiting for selected project...": "Please select the project",
    Amount: "Amount",
    "Problem description": "Problem description",
    "Map link": "Map link",
    "Search at Map.cz": "Search at Map.cz",
    "Date": "Date",
    "There are no ready files yet...": "There are no ready files yet..."
  },
  ProjectsFilters: {
    dates: {
      THREE_DAYS: "3 days",
      ONE_DAY: "1 day",
      ONE_WEEK: "1 week",
      ONE_MOTH: "1 month",
      ONE_YEAR: "1 year",
    },
  },
  ProjectsTable: {
    "Not assigned": "Not assigned",
    "Processed By": "Processed By",
    "Pick users...": "Choose users...",
  },
  ProjectProblem: {
    "Problem description": "Problem description",
    "Problem with project": "Problem with project"
  },
  UsersForms: {
    Username: "Username",
    "First Name": "First Name",
    "Last Name": "Last Name",
    Email: "Email",
    Password: "Password",
    RePassword: "Repeat password",
    "Working Code": "Working Code",
    role: "Role",
    roles: {
      admin: "Admin",
      manager: "Manager",
      operator: "Operator",
      processor: "Processor",
    },
    "New user": "New user",
    "Change new password": "Change password",
    "Change": "Change",
    "Add User": "Add User",
    "Are you sure that you want to delete":
      "Are you sure that you want to delete",
    "Delete user": "Delete user",
    "Edit user": "Edit user",
    Actions: "Actions",
  },
  FilterForms: {
    "Start day": "Start date",
    "End day": "End date",
    projectsStatuses: "Projects Status",
    "Pick status...": "Choose statuses...",
    Sender: "Sender",
    "Pick users...": "Choose users...",
    Assignee: "Assignee",
    Date: "Date",
    "Pick date range...": "Choose date range...",
    "Search...": "Search...",
  },
  ProjectExport: {
    "Instruction": "Instruction",
    "To generate a project file you need:": "To generate a project file you need:",
    "Select start date (Date on the left side) and end date (on the right side)": "Select the start date and end date.",
    "Projects are displayed including selected dates": "Projects will be displayed, including the chosen dates.",
    "The starting date must be less than the final date": "Ensure that the start date is earlier than the end date.",
    "You can also select other filters as you would on the project page": "You can also apply additional available filters.",
    "After selecting all the settings, click the download button and an excel file will be downloaded to your computer": "After selecting all settings, click the download button, and an Excel file will be downloaded to your computer.",
    "Export Data": "Export Data"
  },
  Alerts: {
    "Request failed with status code 400": "Request failed with status code 400",
    "Created": "New project created",
    "EmailCreated": "New Email Created",
    "User Added": "User added",
    "EmailDeleted": "Email deleted",
    "ProjectCreated": "Project created!",
    "ProjectUpdated": "Project updated!",
    "ProjectCreatedWithoutEmail": "Project created, but modeling email was not sent! Please send an email manualy.",
    //*************************Warnings */
    "Maximal uplad size is 4.5MB": "Maximum upload size is 4.5MB",
    //*************************errors */
    "unknown error": "Unknown error. Please try again later",
    "Project id already exists": "Project ID already exists",
    "Network Error": "Network Error",
    "Request failed with status code 401": "Authorisation failure",
    "Please set the Square amount before confirming the project!": "Please set the number of squares before completing the project!",
    "Please select processing person!": "Please select processing person!",
    "Passwords has been changed": "Password has been changed",
    "User Updated": "User updated",
    "Archived": "The project files have been successfully archived",
    "Archiving process failed": "Archiving process failed.",
    "No projects found older than the specified months": "No projects found older than the specified months.",
  },
  ChangePassword: {
    "Password must be at least 8 characters long": "Password must be at least 8 characters long",
    "Password must contain at least one uppercase letter": "Password must contain at least one uppercase letter",
    "Password must contain at least one lowercase letter": "Password must contain at least one lowercase letter",
    "Password must contain at least one digit": "Password must contain at least one digit",
    "Passwords do not match": "Passwords do not match",
  },
  EmailForm: {
    "Delete email" : "Delete email",
    "Are you sure that you want to delete email": "Are you sure that you want to delete email",
  },
  Info: {
    "Help": "Help",
    "If an email fails to be sent during the project creation process, a corresponding message will be displayed.": "If an email fails to be sent during the project creation process, a corresponding message will be displayed.",
    "In such a case, you can manually send the email and upload the project content on the project page": "In such a case, you can manually send the email and upload the project content on the project page",
    "There is a feature that allows you to prioritize a project by selecting the 'Project is urgent' action through the switch, ensuring it receives immediate attention and expedited handling" : "There is a feature that allows you to prioritize a project by selecting the 'Project is urgent' action through the switch, ensuring it receives immediate attention and expedited handling",
    "There is an option to paste files from the clipboard (Ctrl + V)": "There is an option to paste files from the clipboard (Ctrl + V)",
    "An already created project can be marked as urgent using the respective button in the project information.": "An already created project can be marked as urgent using the respective button in the project information.",
    "Afterward, it will move to the top of the projects list and be highlighted with a yellow background." : "Afterward, it will move to the top of the projects list and be highlighted with a yellow background.",
    "Additionally, a feature has been added to report issues with a project using the corresponding button.": "Additionally, a feature has been added to report issues with a project using the corresponding button.",
    "When a problem is reported, the project is highlighted in red.": "When a problem is reported, the project is highlighted in red.",
    "Problem with emails":"Problem with emails",
    "Urgent projects":"Urgent projects",
    "Paste from clipboard":"Paste from clipboard",
    "Troubled project":"Troubled project",
  },
  SystemMonitor: {
    "Storage": "Storage",
    "Archive projects": "Archive",
    "Free": "Free",
    "out of": "out of",
    "GB": "GB",
    "Notice": "Archive project files older than the last ... months",
    "This process": "The archiving process cannot be interrupted, confirm start!",
    "Confirm": "Confirm",
    "Decline": "Decline"
  }
};
