import React, { useState } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from "@mui/material/Button";
import { CustomDialog } from "../CustomDialog";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Alert from '@mui/material/Alert';
import { projectActions, useAppDispatch } from "../../_store";

export function DiskPanel(props: { open: boolean, onClose: Function, hendlLoadingDiskCulc: Function }) {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [alignment, setAlignment] = useState('3');
  const [isSubmiting, setIsSubmiting] = useState(false);

  const handleSubmit = () => {
    setIsSubmiting(true);
  };

  const handleArchiveButtonClick = async (index: number) => {
    dispatch(projectActions.archiveBy({month: index})).finally(() => {props.hendlLoadingDiskCulc(false)});
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    if (newAlignment === null) {
      setAlignment("3");
    }
    else {
      setAlignment(newAlignment);
    }
  };

  const handleConfirm = () => {
    props.onClose();
    setTimeout(() => {
      setIsSubmiting(false);
    }, 100);

    props.hendlLoadingDiskCulc(true)

    handleArchiveButtonClick(parseInt(alignment))
  }

  const handleCancel = () => {
    props.onClose();
    setTimeout(() => {
      setIsSubmiting(false);
    }, 100);
  }

  const dialogActionsContent = (<>
    {isSubmiting ? (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button variant="outlined" onClick={handleConfirm}>
            {t("SystemMonitor.Confirm")}
          </Button>
          <Button variant="contained" onClick={handleCancel}>
            {t("SystemMonitor.Decline")}
          </Button>
        </div>
      </>
    ) :
      (<Button
        variant="contained"
        onClick={handleSubmit}
      >
        {t("SystemMonitor.Archive projects")}
      </Button>)}
  </>)

  const DialogContentForm = (<>

    <Typography sx={{ paddingRight: '2rem' }}>{t("SystemMonitor.Notice")}</Typography>

    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="month"
      fullWidth={true}
      sx={{ marginTop: '1rem' }}
    >
      <ToggleButton value="3">3</ToggleButton>
      <ToggleButton value="6">6</ToggleButton>
      <ToggleButton value="12">12</ToggleButton>
    </ToggleButtonGroup>

    {isSubmiting ? (<Alert severity="warning" sx={{ marginTop: '1rem' }}>{t("SystemMonitor.This process")}</Alert>) : null}

  </>)

  return (
    <CustomDialog
      dialogTitle={t("SystemMonitor.Storage")}
      dialogContent={DialogContentForm}
      dialogActions={dialogActionsContent}
      open={props.open}
      onClose={props.onClose}
    />
  );
}

