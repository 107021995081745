import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Project } from "../../_types";
import { useAppDispatch, useAppSelector, projectActions } from "../../_store";
import { CustomDialog } from "../CustomDialog";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { ProjectStatuses } from "../../_constants";

export function ProjectProblemDialog(props: {
    open: boolean;
    onClose: Function
}) {
    const dispatch = useAppDispatch();
    const [problemDescription, setProblemDescription] = React.useState('');
    const selectedProject: Project = useAppSelector((store) => store.project.selectedProject);

    const { t } = useTranslation();

    const [errors, setErrors] = useState({
        problemDescription: false,
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setProblemDescription(value)
    };
    const subbmitProblem = () => {
        const validationErrors = {
            problemDescription: !problemDescription.trim(),
        };

        if (!Object.values(validationErrors).some((error) => error)) {
            dispatch(projectActions.updateProject({
                id: selectedProject.id,
                status: ProjectStatuses.PROBLEM,
                problemDescription,
            }))
            props.onClose();
        } else {
            setErrors(validationErrors);
        }
    };

    const DialogContentForm = (<Grid sx={{ minWidth: 400 }}>
        <TextField
            label={t("ProjectProblem.Problem description")}
            multiline
            fullWidth
            margin="normal"
            variant="outlined"
            error={errors.problemDescription}
            helperText={errors.problemDescription && t("common.This field is required")}
            name="problem"
            value={problemDescription}
            onChange={handleInputChange}
        />
    </Grid>)

    return (
        <CustomDialog
            dialogTitle={t("ProjectProblem.Problem with project")}
            dialogContent={DialogContentForm}
            dialogActions={
                <Button
                    variant="contained"
                    onClick={subbmitProblem}
                >
                    {t("common.Submit")}
                </Button>
            }
            open={props.open}
            onClose={props.onClose}
        />
    );
}
