import axios from "axios";
import { API_SERVER_URL } from "../_constants";

export const apiClient = axios.create({
  baseURL: API_SERVER_URL,
  headers: {
    "Content-type": "application/json",
    //"Access-Control-Allow-Origin": "*"
    Authorization: `Bearer ${localStorage.getItem('user')}`
  }
});