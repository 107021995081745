import { useEffect } from 'react';
import { LoginComponent } from '../_components';
import { loginActions, useAppDispatch, useAppSelector } from '../_store'
import { Fade, LinearProgress } from '@mui/material';
import { SessionValidation } from '../_constants/sessionValidation';

interface Props {
  children: JSX.Element;
}

export function Login(props: Props) {
  const dispatch = useAppDispatch();
  const isSessionValid = useAppSelector((state) => state.login.isSessionValid);
  useEffect(() => {
    dispatch(loginActions.validateSession())
  }, [isSessionValid]);
  return (
    <>
      <Fade
        in={isSessionValid === SessionValidation.INVALID}
        timeout={{ enter: 0, exit: 0, }}
        unmountOnExit
      >
        <span><LoginComponent /></span>
      </Fade>
      <Fade
        in={isSessionValid === SessionValidation.UNKNOWN}
        timeout={{ enter: 0, exit: 0, }}
        unmountOnExit
      >
        <LinearProgress color="primary" />
      </Fade>
      <Fade
        in={isSessionValid === SessionValidation.VALID}
        timeout={{ enter: 0, exit: 0, }}
        unmountOnExit
      >
        <span>
          {props.children}
        </span>
      </Fade>
    </>
  )
}
