import { Buffer } from "buffer";

export async function readFileAsBuffer(file: File) {
    try {
        const arrbuf = await file.arrayBuffer();
        const buffer = Buffer.from(arrbuf);
        // Read the file as a Buffer
        return buffer;
    } catch (err) {
        throw new Error(`Error reading file: ${err}`);
    }
}

export function isImageFile(url: string) {
    const supportedImageExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".bmp",
      ".svg",
    ];
  
    // Extract the file extension from the URL
    const fileExtension = url.substring(url.lastIndexOf("."));
  
    // Check if the extension is in the list of supported image extensions
    return supportedImageExtensions.includes(fileExtension.toLowerCase());
  }