import { Grid } from '@mui/material';
import { MainContainer, MainDrawer, } from '../_components';
import { CreateEmail } from '../_components/emailComponents/CreateEmail';
import { EmailList } from '../_components/emailComponents/EmailList';

export function EmailContainer() {
  return (
    <>
      <MainContainer
        drawer={<MainDrawer />}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CreateEmail />
          </Grid>
          <Grid item xs={12}>
            <EmailList />
          </Grid>
        </Grid>
      </MainContainer>
    </>
  )
}