import { useEffect, useState } from "react";
import { useAppSelector, projectActions, useAppDispatch } from "../../_store";
import { Modal as BaseModal } from "@mui/base/Modal";
import { Button, Card, CardContent, Grid, IconButton, Typography, createTheme, css, styled } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { ARCHIVE_EXTENSIONS } from "../../_constants/extensionsGrouping";
import { getFileName, getfileExtension } from "../../_helpers/pathProcessor";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CloseIcon from '@mui/icons-material/Close';

import React from "react";
import { useTranslation } from "react-i18next";
import { themeConfig } from "../template/createCentralizedTheme";
import { AddFiles } from "../AddFiles";
import { readFileAsBuffer } from "../../_helpers/fileProcessing.halper";
import { UsersRoles } from "../../_constants";
import { Project } from "../../_types";

export function ProjectFiles() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const { t } = useTranslation();
    const theme = createTheme(themeConfig());    
    const selectedProjectIsLoading = useAppSelector((store) => store.project.loaders.selectedProjectIsLoading);

    const files = useAppSelector((store) => store.project.current_files);
    const imgShownList = useAppSelector((store) => store.project.imgs_for_shown);
    const selectedProject: Project = useAppSelector((store) => store.project.selectedProject);
    const userRole = useAppSelector((store) => store.login.JWTtoken?.role)
    const [filesForUpdate, setFilesForUpdate] = useState<File[]>([]);

    const [isEditMode, setIsEditMode] = useState(false);

    const handleSendFiles = async () => {
        const fileBuffers = await Promise.all(filesForUpdate.map(readFileAsBuffer));
        const filenames = filesForUpdate.map((file) => {
            return file.name;
        });

        dispatch(
            projectActions.sendFiles({
                id: selectedProject.id,
                namesOfFiles: filenames,
                fileBufferArray: fileBuffers,
                done: false
            })
        );
        setIsEditMode(false)
        setFilesForUpdate([])
    }

    const hendleDeleteFile = async (index: number) => {
        dispatch(
            projectActions.deleteFile({
                id: selectedProject.id,
                namesOfFiles: getFileName(files[index])
            })
        );
        setIsEditMode(false)
    }

    useEffect(() => {
        setIsEditMode(false)
    }, [selectedProject]);

    const filesList = files.map((file, index) => {
        return (
            <Grid key={`file-${index}`} container item>
                <Grid item xs={10}>
                    <Button
                        fullWidth
                        variant="text"
                        href={file}
                        download={getFileName(file)}
                        startIcon={ARCHIVE_EXTENSIONS.indexOf(getfileExtension(file)) !== -1 ? <FolderZipIcon fontSize="large" /> : <InsertDriveFileIcon fontSize="large" />}
                        color="secondary"
                        sx={{
                            textTransform: 'none',
                            justifyContent: 'left'
                        }}
                    >
                        <Typography>{getFileName(file)}</Typography>
                    </Button>
                </Grid>
                <Grid item xs={2} sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'flex-end'
                }}>
                    {isEditMode &&
                        <>
                            <IconButton aria-label="delete" size="small" onClick={() => hendleDeleteFile(index)}>
                                <DeleteForeverIcon color="secondary" />
                            </IconButton>
                        </>}
                </Grid>
            </Grid>
        )
    })
    const FilesSection = (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Typography variant="h6" color='text.secondary'>{t(`ProjectActions.All Files`)}</Typography>
                        {isEditMode ?
                            <>
                                <IconButton aria-label="edit" size="small" onClick={() => { setIsEditMode(false) }}>
                                    <CloseIcon color="secondary" />
                                </IconButton>
                            </>
                            :
                            <>
                                {(userRole === UsersRoles.ADMIN || userRole === UsersRoles.MANAGER || userRole === UsersRoles.OPERATOR) &&
                                    <IconButton aria-label="edit" size="small" onClick={() => { setIsEditMode(true) }}>
                                        <DriveFileRenameOutlineIcon color="secondary" />
                                    </IconButton>
                                }
                            </>
                        }
                    </Grid>
                    {filesList}
                    {isEditMode &&
                        <Grid item xs={12}>
                            <AddFiles color='secondary' files={filesForUpdate} setFiles={setFilesForUpdate} />
                            <Button
                                disabled={selectedProjectIsLoading}
                                color='secondary' 
                                fullWidth
                                variant="contained"
                                onClick={handleSendFiles}
                                sx={{ mt: 2 }}
                            >
                                {t("common.Send")}
                            </Button>
                        </Grid>}
                </Grid>
            </CardContent>
        </Card>
    )
    const grey = {
        50: "#F3F6F9",
        100: "#E5EAF2",
        200: "#DAE2ED",
        300: "#C7D0DD",
        400: "#B0B8C4",
        500: "#9DA8B7",
        600: "#6B7A90",
        700: "#434D5B",
        800: "#303740",
        900: "#1C2025",
    };

    const Modal = styled(BaseModal)`
    position: fixed;
    z-index: 1300;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

    const ModalContent = styled("div")(
        ({ theme }) => css`
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 500;
      text-align: start;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow: hidden;
      background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border-radius: 8px;


      & .modal-title {
        margin: 0;
        line-height: 1.5rem;
        margin-bottom: 8px;
      }

      & .modal-description {
        margin: 0;
        line-height: 1.5rem;
        font-weight: 400;
        color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
        margin-bottom: 4px;
      }
    `
    );

    return (
        <>
            {FilesSection}
            {/* Lightbox */}
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={open}
                onClose={() => { setOpen(false) }}
            // slots={{ backdrop: StyledBackdrop }}
            >
                <ModalContent
                    sx={{
                        margin: "auto", // Center the modal
                        overflow: "hidden", // Hide the scrollbar
                        display: "flex",
                        flexDirection: "column",
                        position: "relative", // Set position to relative for absolute positioning of the close button
                    }}
                >
                    <IconButton
                        edge="end"
                        onClick={() => { setOpen(false) }}
                        sx={{
                            position: "absolute",
                            top: "-7px",
                            right: "5px",
                            background: "none",
                            color: "#7d837c",
                            "&:hover": {
                                color: "#d0d9cf", // Change the color on hover
                                background: "none",
                            },
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                    <img
                        className="fit-picture"
                        src={imgShownList[lightboxIndex]}
                        alt="Grapefruit slice atop a pile of other slices"
                        style={{
                            maxHeight: "90vh", // Set the max height of the image to 90% of the viewport height
                        }}
                    />
                </ModalContent>
            </Modal>
        </>
    );
}

