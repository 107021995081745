import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export function CustomDialog(props: { dialogContent: JSX.Element | string, dialogTitle: string, dialogActions: JSX.Element, open: boolean, onClose: Function }) {
    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => { props.onClose() }}
                sx={{
                    position: 'absolute',
                    right: 11,
                    top: 11,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {props.dialogContent}
            </DialogContent>
            <DialogActions sx={{padding: "16px 24px"}}>
                {props.dialogActions}
            </DialogActions>
        </Dialog>
    );
}
