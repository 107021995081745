import Button from "@mui/material/Button";
import { UpdateUserDto } from "../../_types";
import { usersActions, useAppDispatch } from "../../_store";
import { CustomDialog } from "../CustomDialog";
import { useTranslation } from "react-i18next";

export function DeleteUserForm(props: { open: boolean, onClose: Function, user: UpdateUserDto | null; }) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleDelete = async (user: UpdateUserDto | null) => {
    if (user) {
      dispatch(usersActions.deleteUser(user.id));
      props.onClose();
    } else {
      throw new Error("Empty USER");
    }
  };

  return (
    <CustomDialog
      dialogTitle={t("UsersForms.Delete user")}
      dialogContent={(<>{t("UsersForms.Are you sure that you want to delete")} <strong>{props.user?.userName}</strong>?</>)}
      dialogActions={
        <>
          <Button
            variant="outlined"
            onClick={() => props.onClose()}
          >
            {t("common.No")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(props.user)}
          >
            {t("common.Yes")}
          </Button>
        </>
      }
      open={props.open}
      onClose={props.onClose}
    />
  );
}
