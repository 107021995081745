export function getFileName(file: string){

    let fileName: string | string[] = file.split('\\')
    fileName = fileName[fileName.length - 1].split('/')
    fileName = fileName[fileName.length - 1]

    return fileName
}

export function getfileExtension(file: string){

    return getFileName(file).split('.').pop() || ''
}