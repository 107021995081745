import { useEffect, useState } from "react";
import { Buffer } from "buffer";
import { MainContainer, MainDrawer } from ".";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fade,
  createTheme,
  FormControlLabel,
  Switch,
  InputAdornment,
} from "@mui/material";

import {
  projectActions,
  useAppDispatch,
  useAppSelector,
  emailsActions,
  alertActions,
} from "../_store";
import { useTranslation } from "react-i18next";
import { AddFiles } from "./AddFiles";
import { themeConfig } from "./template/createCentralizedTheme";
import { externalIdMatcher } from "../_constants";
import { readFileAsBuffer } from "../_helpers/fileProcessing.halper";
import { ADD_UPLOAD_LIMIT } from "../_constants/fileConstants";

export function AddProjectComponent() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [externalId, setExternalId] = useState("");
  const [address, setAddress] = useState("");
  const [gps, setGps] = useState("");
  const [noteModeling, setNoteModeling] = useState("");
  const [noteProcessing, setNoteProcessing] = useState("");
  const [selectedEmail, setSelectedOption] = useState(0);
  const [isUrgent, setIsUrgent] = useState(false);
  const [isAdjustment, setIsAdjustment] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const theme = createTheme(themeConfig());

  useEffect(() => {
    dispatch(emailsActions.getAllEmails());
  }, [dispatch]);

  const emails = useAppSelector((store) => store.email.emails);
  const projectIsAdding = useAppSelector(
    (store) => store.project.loaders.projectIsAdding
  );
  const emailListIsLoading = useAppSelector(
    (store) => store.email.loaders.emailListIsLoading
  );
  const rejected = useAppSelector((store) => store.project.rejected);

  function clearFields(){
    setName("");
    setAddress("");
    setGps("");
    setNoteModeling("");
    setNoteProcessing("");
    setFiles([]);
    setErrors({
      name: false,
      address: false,
      gps: false,
      noteModeling: false,
      noteProcessing: false,
    });
    setExternalId("");
  }

  useEffect(() => {
    if (rejected === false) {
      clearFields()
    }
  }, [rejected]);

  const [errors, setErrors] = useState<{
    name: string | boolean;
    address: boolean;
    gps: boolean;
    noteModeling: boolean;
    noteProcessing: boolean;
  }>({
    name: false,
    address: false,
    gps: false,
    noteModeling: false,
    noteProcessing: false,
    // file: false,
  });

  const handleSubmit = async () => {
    const validationErrors = {
      name: name.trim() ? false : t("common.This field is required"),
      address: !address.trim(),
      gps: !gps.trim(),
      noteModeling: false,
      noteProcessing: false,
    };

    if (!Object.values(validationErrors).some((error) => error)) {

      // Convert files to an array of file buffers
      const fileBuffers = await Promise.all(files.map(readFileAsBuffer));
      const filenames = files.map((file) => {
        return file.name;
      });
      let filesSize = 0
      fileBuffers.map((buffer) => {
        filesSize += Buffer.byteLength(buffer)
      })
      // Call your dispatch function with the necessary data
      if (filesSize > ADD_UPLOAD_LIMIT) {
        dispatch(alertActions.setMessage({
          alert: {
            type: 'warning',
            message: 'Maximal uplad size is 4.5MB'
          }
        }))
      } else {
        dispatch(projectActions.forceSendProjectLoader());
        dispatch(
          projectActions.sendProject({
            processingEmail: selectedEmail,
            name: name,
            address: address,
            gps: gps,
            notesForModeling: noteModeling,
            notesForProcessing: noteProcessing,
            namesOfFiles: filenames,
            fileBufferArray: fileBuffers,
            isAdjustment,
            isUrgent,
          })
        );
      }
    }
    // Reset state values after successful submission

    setErrors(validationErrors);
  };

  return (
    <MainContainer globalLoader={projectIsAdding} drawer={<MainDrawer />}>
      <>
        <Grid
          container
          spacing={2}
        >
          <Grid item
            xl={7}
            sx={{ margin: "auto" }}
            xs={12}>
            <Card sx={{ width: "100%", display: "flex" }}>
              <CardContent sx={{ flex: 1 }}>
                <>
                  <FormControl disabled={isAdjustment} fullWidth sx={{ mt: 2 }}>
                    <InputLabel htmlFor="post">
                      {t("AddProject.Email for modelling")}
                    </InputLabel>
                    <Select
                      label={t("AddProject.Email for modelling")}
                      inputProps={{
                        name: "post",
                        id: "post",
                      }}
                      value={selectedEmail}
                      onChange={(e) =>
                        setSelectedOption(Number(e.target.value))
                      }
                      disabled={emailListIsLoading || isAdjustment}
                    >
                      {selectedEmail === 0 && (
                        <MenuItem value="0">
                          <Fade
                            in={emailListIsLoading}
                            onExit={() => {
                              setSelectedOption(emails && emails.length > 0 ? emails[0].id : null);
                            }}
                            unmountOnExit
                          >
                            <span>
                              {t("AddProject.Modeling emails are loading...")}
                            </span>
                          </Fade>
                        </MenuItem>
                      )}
                      {emails.map((email, key) => (
                        <MenuItem key={`modelingEmail-${key}`} value={email.id}>
                          {email.email}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label={t("AddProject.Name")}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={Boolean(errors.name)}
                    InputProps={{
                      startAdornment: isAdjustment || isUrgent ? <InputAdornment position="start">{isAdjustment?'uprava-':'urgent-'}</InputAdornment> : undefined
                    }}
                    helperText={
                      errors.name || (externalId && `ID: ${externalId}`)
                    }
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      const externalIdMatch =
                        e.target.value.match(externalIdMatcher);
                      if (externalIdMatch && externalIdMatch[1]) {
                        setErrors({
                          ...errors,
                          name: false,
                        });
                        if(isAdjustment || isUrgent) {
                          if(isAdjustment) {
                            
                          }
                          setExternalId('uprava-' + externalIdMatch[1]);
                          setExternalId('uprava-' + externalIdMatch[1]);
                        } else {
                          setExternalId(externalIdMatch[1]);
                        }
                      } else {
                        setErrors({
                          ...errors,
                          name: t("AddProject.Can`t detect project  ID"),
                        });
                        setExternalId("");
                      }
                    }}
                  />

                  <TextField
                    label={t("AddProject.Address")}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={errors.address}
                    helperText={
                      errors.address && t("common.This field is required")
                    }
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />

                  <TextField
                    label={t("AddProject.GPS")}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={errors.gps}
                    helperText={
                      errors.gps && t("common.This field is required")
                    }
                    value={gps}
                    onChange={(e) => setGps(e.target.value)}
                  />

                  <TextField
                    label={t("AddProject.Notes for modelling")}
                    fullWidth
                    disabled={isAdjustment}
                    margin="normal"
                    multiline
                    rows={3}
                    variant="outlined"
                    value={noteModeling}
                    onChange={(e) => setNoteModeling(e.target.value)}
                  />

                  <TextField
                    label={t("AddProject.Notes for processing")}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={3}
                    variant="outlined"
                    value={noteProcessing}
                    onChange={(e) => setNoteProcessing(e.target.value)}
                  />
                </>
                {/* File upload */}
                <AddFiles
                  style={{ marginTop: theme.spacing(2) }}
                  files={files}
                  setFiles={setFiles}
                  clipboardPaste={true}
                />
                <FormControlLabel
                  style={{
                    marginTop: theme.spacing(2),
                    color: theme.palette.grey[700],
                  }}
                  control={
                    <Switch
                      color="warning"
                      value={isUrgent}
                      onChange={(e, checked) => {
                        setIsUrgent(checked);
                      }}
                    />
                  }
                  label={t("AddProject.Project is urgent")}
                />
                <FormControlLabel
                  style={{
                    marginTop: theme.spacing(2),
                    color: theme.palette.grey[700],
                  }}
                  control={
                    <Switch
                      color="warning"
                      value={isAdjustment}
                      onChange={(e, checked) => {
                        setIsAdjustment(checked);
                      }}
                    />
                  }
                  label={t("AddProject.Adjustment")}
                />
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ mt: 2 }}
                  disabled={projectIsAdding || emailListIsLoading}
                >
                  {t("common.Send")}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    </MainContainer>
  );
}
