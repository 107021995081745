import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'

import { projectReducer } from './project/project.slice';
import { emailsReducer } from './emails.slice';
import { usersReducer } from './users.slice';
import { loginReducer } from './login.slice';
import { alertReducer } from './alert.slice';
export * from './project/project.slice';
export * from './emails.slice';
export * from './users.slice';
export * from './login.slice';
export * from './alert.slice';

export const store = configureStore({
    reducer: {
        project: projectReducer,
        email: emailsReducer,
        users: usersReducer,
        login: loginReducer,
        alert: alertReducer,
    },
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector