import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { themeConfig } from "./template/createCentralizedTheme";
import createTheme from '@mui/material/styles/createTheme';
import { projectActions, useAppDispatch, useAppSelector } from '../_store';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

function bytesToGigabytes(bytes: number): string {
    const gigabytes = bytes / (1024 ** 3);
    return gigabytes.toFixed(1);
}

function calculateUsedDiskPercentage(totalDisk: number, freeDisk: number): number {
    if (totalDisk === 0) {
        return 0;
    }
    const usedDisk = totalDisk - freeDisk;
    const usedPercentage = (usedDisk / totalDisk) * 100;
    return Math.round(usedPercentage);
}

const DiscSpaceComponent = (props: {diskCulcIsLoading: boolean}) => {
    const theme = createTheme(themeConfig());
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const discSpace = useAppSelector((store) => store.project.DiscSpace);

    const [progress, setProgress] = useState(50);
    const [totalDisk, setTotalDisk] = useState(0);
    const [freeDisk, setFreeDisk] = useState(0);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => {
        setIsLoad(props.diskCulcIsLoading)
    }, [props.diskCulcIsLoading])

    useEffect(() => {
        const storedTotalDisk = localStorage.getItem('totalDisk');
        const storedFreeDisk = localStorage.getItem('freeDisk');
        const storedCalculatedUDP = localStorage.getItem('calculatedUDP');

        if (storedTotalDisk && storedFreeDisk && storedCalculatedUDP) {
            setTotalDisk(parseFloat(storedTotalDisk));
            setFreeDisk(parseFloat(storedFreeDisk));
            const calculatedUDP = parseInt(storedCalculatedUDP);
            setProgress(calculatedUDP);

            setIsLoad(false);
        } else {
            dispatch(projectActions.getDiscSpace());
        }
    }, [dispatch]);

    useEffect(() => {
        if (discSpace.size && discSpace.free) {

            const diskSizeInGb = parseFloat(bytesToGigabytes(discSpace.size));
            const diskFreeInGb = parseFloat(bytesToGigabytes(discSpace.free));

            setTotalDisk(diskSizeInGb);
            setFreeDisk(diskFreeInGb);
            const calculatedUDP = calculateUsedDiskPercentage(diskSizeInGb, diskFreeInGb);
            setProgress(calculatedUDP);

            localStorage.setItem('totalDisk', diskSizeInGb.toString());
            localStorage.setItem('freeDisk', diskFreeInGb.toString());
            localStorage.setItem('calculatedUDP', calculatedUDP.toString());

            setIsLoad(false);
        }
    }, [discSpace]);

    return (<>
        {isLoad ? 
        (<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}><CircularProgress color="success" sx={{scale: '0.8'}}/></div>)
        :
        (<Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography sx={{
                fontSize: 14,
                color: theme.palette.grey[700],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
                variant="subtitle1">
                {t("SystemMonitor.Free")}       {/* Free */}
                {freeDisk}                      {/* 200 */}
                {t("SystemMonitor.GB")}         {/* GB */}
                {t("SystemMonitor.out of")}     {/* out of */}
                {totalDisk}                     {/* 400 */}
                {t("SystemMonitor.GB")}         {/* GB */}

                <LaunchIcon sx={{ scale: '0.8' }}/>
            </Typography>
        </Box>)}
        </>
    );
};

export default DiscSpaceComponent;
