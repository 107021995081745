import { useAppSelector, projectActions, useAppDispatch } from "../../../_store";
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Autocomplete, OutlinedInputProps, TextField, Typography, createTheme } from "@mui/material";
import { ProjectStatuses } from "../../../_constants/ProjectStatuses";
import { useTranslation } from "react-i18next";
import { User } from "../../../_types";
import { UsersRoles } from "../../../_constants";
import { themeConfig } from "../../template/createCentralizedTheme";

export function ProcessedByCell({ cellParams }: { cellParams: GridRenderCellParams }) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const availableFilters = useAppSelector((store) => store.project.availableFilters);
    const userRole = useAppSelector((store) => store.login.JWTtoken?.role)
    const theme = createTheme(themeConfig());
    const isSelectionDisabled = (): boolean => {
        if (cellParams.row.status === ProjectStatuses.READY_FOR_ASSIGNMENT || cellParams.row.status === ProjectStatuses.PROCESSING || cellParams.row.status === ProjectStatuses.READY_FOR_CHECKING) {
            return false
        } else {
            return true
        }
    }
    return (
        <>
            {(userRole === UsersRoles.MANAGER || userRole === UsersRoles.ADMIN) && !isSelectionDisabled() ?
                <>
                    {availableFilters?.assignee &&
                        <Autocomplete
                            disabled={isSelectionDisabled()}
                            disableClearable={true}
                            fullWidth
                            options={availableFilters.assignee}
                            getOptionLabel={(option) => option.userName}
                            value={cellParams.value}
                            isOptionEqualToValue={(option: User, value: User) => {
                                return option.id === value.id
                            }}
                            onChange={(e, value) => {
                                dispatch(projectActions.updateProject({
                                    id: cellParams.row.id,
                                    processedBy: value ? value : undefined,
                                    status: ProjectStatuses.PROCESSING
                                }))
                            }}
                            renderInput={(params) => {
                                params.inputProps.style = {
                                    ...params.inputProps.style,
                                    fontSize: 15
                                }
                                return (
                                    <TextField
                                        {...params}
                                        size="small"
                                        name="procesdBy"
                                        variant="standard"
                                        placeholder={t(`ProjectsTable.Pick users...`)}
                                    />
                                )
                            }}
                        />
                    }
                </>
                :
                <>
                    {cellParams?.value?.userName ?
                        <Typography fontSize={15}>{cellParams?.value?.userName}</Typography>
                        :
                        "---"
                    }
                </>
            }
        </>
    );
}



