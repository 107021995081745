import { useEffect } from "react";
import { useAppSelector, projectActions, useAppDispatch } from "../../_store";
import { DataGrid, GridColDef, GridEventListener, GridFooter, GridValueGetterParams, useGridApiContext, useGridApiEventHandler } from '@mui/x-data-grid';
import { Alert, Autocomplete, Box, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import React from "react";
import { useTranslation } from "react-i18next";
import { ProjectStatusesColors } from "../../_constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import { makeEndDate, makeStartDate } from "../../_helpers/date.halper";
import { ProjectStatisticBar } from "./ProjectStatisticBar";


export function ProjectsFilters({ variant }: { variant?: 'dataExport' | undefined }) {
    const skipGettingProjects = variant === 'dataExport' ? true : false
    const fieldsVariant = variant === 'dataExport' ? 'outlined' : 'outlined' //'standard'
    const dispatch = useAppDispatch();
    const availableFilters = useAppSelector((store) => store.project.availableFilters);
    const appliedFilters = useAppSelector((store) => store.project.appliedFilters);
    const { t } = useTranslation();
    const elementsSize = variant === 'dataExport' ? 'medium' : 'small'

    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs(new Date()));
    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs(new Date()));

    useEffect(() => {
        dispatch(projectActions.getFilters());
        if(variant === 'dataExport') {
            dispatch(projectActions.applyFilters({
                skipGettingProjects,
                filters: {
                    ...appliedFilters,
                    startDate: makeStartDate(new Date()).toISOString(),
                    endDate: makeEndDate(new Date()).toISOString()
                }
            }))
        }        
    }, [dispatch]);

    return (
        <Card style={{ padding: 10 }}>
            {availableFilters &&
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size={elementsSize}
                            id="project-statuses-filter"
                            options={Object.keys(availableFilters?.statuses)}
                            getOptionLabel={(option) => t(`common.projectStatus.${availableFilters?.statuses[option as keyof typeof availableFilters.statuses]}`)}
                            value={appliedFilters.statuses}
                            onChange={(e, value) => {
                                dispatch(projectActions.applyFilters({
                                    skipGettingProjects,
                                    filters: {
                                        ...appliedFilters,
                                        statuses: value
                                    }
                                }))
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <span {...props} style={{ color: ProjectStatusesColors[availableFilters.statuses[option as keyof typeof availableFilters.statuses] as keyof typeof ProjectStatusesColors] }}>
                                        {t(`common.projectStatus.${availableFilters?.statuses[option as keyof typeof availableFilters.statuses]}`)}
                                    </span>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={fieldsVariant}
                                    label={t(`FilterForms.projectsStatuses`)}
                                    placeholder={t(`FilterForms.Pick status...`)}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={variant === 'dataExport' ? 3 : 2}>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size={elementsSize}
                            id="sender-filter"
                            options={availableFilters.senders}
                            getOptionLabel={(option) => option.userName}
                            value={appliedFilters.senders}
                            onChange={(e, value) => {
                                dispatch(projectActions.applyFilters({
                                    skipGettingProjects,
                                    filters: {
                                        ...appliedFilters,
                                        senders: value
                                    }
                                }))
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={fieldsVariant}
                                    label={t(`FilterForms.Sender`)}
                                    placeholder={t(`FilterForms.Pick users...`)}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={variant === 'dataExport' ? 3 : 2}>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size={elementsSize}
                            id="assignee-filter"
                            options={availableFilters.assignee}
                            getOptionLabel={(option) => option.userName}
                            value={appliedFilters.assignee}
                            onChange={(e, value) => {
                                dispatch(projectActions.applyFilters({
                                    skipGettingProjects,
                                    filters: {
                                        ...appliedFilters,
                                        assignee: value
                                    }
                                }))
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={fieldsVariant}
                                    label={t(`FilterForms.Assignee`)}
                                    placeholder={t(`FilterForms.Pick users...`)}
                                />
                            )}
                        />
                    </Grid>
                    {variant !== 'dataExport' &&
                        <Grid item xs={2}>
                            <Autocomplete
                                id="date-filter"
                                size={elementsSize}
                                options={Object.keys(availableFilters?.dates)}
                                getOptionLabel={(option) => t(`ProjectsFilters.dates.${option}`)}
                                value={appliedFilters.dates}
                                onChange={(e, value) => {
                                    dispatch(projectActions.applyFilters({
                                        skipGettingProjects,
                                        filters: {
                                            ...appliedFilters,
                                            dates: value
                                        }
                                    }))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant={fieldsVariant}
                                        label={t(`FilterForms.Date`)}
                                        placeholder={t(`FilterForms.Pick date range...`)}
                                    />
                                )}
                            />
                        </Grid>
                    }
                    {variant !== 'dataExport' &&
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                size={elementsSize}
                                id="search-string"
                                value={appliedFilters.searchString}
                                onChange={(e) => {
                                    dispatch(projectActions.applyFilters({
                                        skipGettingProjects,
                                        filters: {
                                            ...appliedFilters,
                                            searchString: e.target.value
                                        }
                                    }))
                                }}
                                label={t(`FilterForms.Search...`)}
                                variant={fieldsVariant} />
                        </Grid>
                    }
                    {variant === 'dataExport' &&
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{width: "100%"}}
                                    label={t(`FilterForms.Start day`)}
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue)
                                        dispatch(projectActions.applyFilters({
                                            skipGettingProjects,
                                            filters: {
                                                ...appliedFilters,
                                                startDate: makeStartDate(newValue?.toDate()).toISOString()
                                            }
                                        }))
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    }
                    {variant === 'dataExport' &&
                        <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{width: "100%"}}
                                    label={t(`FilterForms.End day`)}
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue)
                                        dispatch(projectActions.applyFilters({
                                            skipGettingProjects,
                                            filters: {
                                                ...appliedFilters,
                                                endDate: makeEndDate(newValue?.toDate()).toISOString()
                                            }
                                        }))
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    }
                </Grid>
            }
            {variant !== 'dataExport' && <ProjectStatisticBar />}
        </Card>
    );
}
