import {
  createSlice,
  ActionReducerMapBuilder,
  createAction,
} from "@reduxjs/toolkit";

import { AlertType } from "../_types";

const name = "alerts";
const initialState = createInitialState();

const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers,
});

const extraActions = createExtraActions();
// exports

export const alertActions = { ...slice.actions, ...extraActions };
export const alertReducer = slice.reducer;

// implementation
interface AlertsSlice {
  alert: {
    type: AlertType;
    message: string;
  } | null;  
  alertHeight?: number
}

function createInitialState() {
  const initialState: AlertsSlice = {
    alert: null,
  };
  return initialState;
}

function createExtraActions() {
  return {
    clearState: clearState(),
    setMessage: setMessage(),
    setGlobalAlertHeight: setGlobalAlertHeight()
  };

  function setGlobalAlertHeight() {
    return createAction(
      `${name}/setGlobalAlertHeight`,
      function prepare(height:number) {
        return {
          payload: {
            height,
          },
        };
      }
    );
  }

  function clearState() {
    return createAction(`${name}/clearState`);
  }

  function setMessage() {
    return createAction(
      `${name}/setMessage`,
      function prepare({
        alert,
      }: {
        alert: {
          type: AlertType;
          message: string;
        } | null;
      }) {
        return {
          payload: {
            alert,
          },
        };
      }
    );
  }
}

function createExtraReducers() {
  return (builder: ActionReducerMapBuilder<typeof initialState>) => {
    clearState();
    setMessage();
    setGlobalAlertHeight();

    function setGlobalAlertHeight() {
      builder.addCase(extraActions.setGlobalAlertHeight, (state, action) => {
        state.alertHeight = action.payload.height;
      });
    }

    function clearState() {
      builder.addCase(extraActions.clearState, (state, action) => {
        state.alert = null;
      });
    }

    function setMessage() {
      builder.addCase(extraActions.setMessage, (state, action) => {
        state.alert = action.payload.alert;
      });
    }
  };
}
