import { Grid } from '@mui/material';
import { MainContainer, MainDrawer } from '../_components';
import { ProjectsTable } from '../_components/projectsComponents/ProjectTable/ProjectsTable';
import { ProjectsCard } from '../_components/projectsComponents/ProjectsCard';
import { ProjectsFilters } from '../_components/projectsComponents/ProjectsFilters';
import { ProjectDataExportBar } from '../_components/projectsComponents/ProjectDataExportBar';
import { useAppSelector } from '../_store';

export function ProjectDataExport() {
  const exportInProgress = useAppSelector((store) => store.project.loaders.exportInProgress);
  return (
    <>
      <MainContainer
        globalLoader={exportInProgress}
        drawer={<MainDrawer />}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProjectsFilters variant="dataExport" />
          </Grid>
          <Grid item xs={12}>
            <ProjectDataExportBar />
          </Grid>
        </Grid>
      </MainContainer>
    </>
  )
}
