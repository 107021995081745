import { csCZ, ukUA } from "@mui/x-data-grid";
import { locales } from "./config";

export const GetDataGridLocalization = () => {
    let lang = localStorage.getItem('language')
    if(lang === locales.ua) {
        return ukUA.components.MuiDataGrid.defaultProps.localeText
    }
    if(lang === locales.cz) {
        return csCZ.components.MuiDataGrid.defaultProps.localeText
    }
    return {}
}