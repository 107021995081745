import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { UpdateUserDto } from "../../_types";
import { usersActions, useAppDispatch, alertActions } from "../../_store";
import { CustomDialog } from "../CustomDialog";
import { useTranslation } from "react-i18next";
import { sha3_512 } from 'js-sha3';
import { passwordValidator } from "../../_helpers";

export function ChangePassword(props: { open: boolean, onClose: Function }) {
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const { t } = useTranslation();

  const [selectedUser, setSelectedUser] = useState<UpdateUserDto | null>(null);

  const [errorsPassword, setErrorsPassword] = useState(
    {
      text: "",
      alert: false
    });
  const [errorsRePassword, setErrorsRePassword] = useState(
    {
      text: "",
      alert: false
    });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (selectedUser) {
      setSelectedUser((prevUser) => ({
        ...(prevUser as UpdateUserDto),
        [name]: value,
      }));
    } else {
      switch (name) {
        case "password":
          setPassword(value);
          break;
        case "rePassword":
          setRePassword(value);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = () => {

    let passwordError = passwordValidator(password)

    if (passwordError == '') {
      setErrorsPassword({
        text: "",
        alert: false
      })
      if (rePassword != "" && password === rePassword) {
        setErrorsRePassword({
          text: "",
          alert: false
        })

        setRePassword("");
        setPassword("");

        let hashedPassword: string = sha3_512(rePassword)

        dispatch(
          usersActions.changePassword({
            password: hashedPassword
          })
        );
        props.onClose();
      } else {
        setErrorsRePassword({
          text: t("ChangePassword.Passwords do not match"),
          alert: true
        })
      }
    }
    else {
      setErrorsPassword({
        text: passwordError,
        alert: true
      })
    }
  };
  const DialogContentForm = (<>

    <TextField
      label={t("UsersForms.Password")}
      fullWidth
      margin="normal"
      variant="outlined"
      type="password"
      error={errorsPassword.alert}
      helperText={errorsPassword.text}
      value={password}
      name="password"
      onChange={handleInputChange}
    />

    <TextField
      label={t("UsersForms.RePassword")}
      fullWidth
      margin="normal"
      variant="outlined"
      type="password"
      error={errorsRePassword.alert}
      helperText={errorsRePassword.text}
      value={rePassword}
      name="rePassword"
      onChange={handleInputChange}
    />

  </>)
  return (
    <CustomDialog
      dialogTitle={t("UsersForms.Change new password")}
      dialogContent={DialogContentForm}
      dialogActions={
        <Button
          variant="contained"
          onClick={handleSubmit}
        >
          {t("UsersForms.Change")}
        </Button>
      }
      open={props.open}
      onClose={props.onClose}
    />
  );
}
