export function makeStartDate(date?: Date):Date {
    if(!date) return new Date
    date.setHours(0,0,0,0);
    return date
}

export function makeEndDate(date?: Date):Date {
    if(!date) return new Date
    date.setHours(0,0,0,0);
    return new Date(Number(date) + (1000 * 60 * 60 * 24))
}