import { useEffect, useState } from "react";
import { useAppSelector } from "../../_store";
import { Button, Card, CardContent, Grid, IconButton, Typography, createTheme } from "@mui/material";

import FolderZipIcon from '@mui/icons-material/FolderZip';
import { AddFiles } from "../AddFiles"
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useTranslation } from "react-i18next";
import { themeConfig } from "../template/createCentralizedTheme";
import { ARCHIVE_EXTENSIONS, UsersRoles } from "../../_constants";
import { getFileName, getfileExtension } from "../../_helpers";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CloseIcon from '@mui/icons-material/Close';

export function DoneFiles() {
    const { t } = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    // const theme = createTheme(themeConfig());
    const pathToDoneFile = useAppSelector((store) => store.project.pathToDoneFile);
    const userRole = useAppSelector((store) => store.login.JWTtoken?.role)
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if (((pathToDoneFile && pathToDoneFile?.length <= 0) || !pathToDoneFile) && userRole !== UsersRoles.OPERATOR) {
            setIsEditMode(true)
        } else {
            setIsEditMode(false)
        }
    }, [pathToDoneFile])

    const filesList = (pathToDoneFile || []).map((file, index) => {
        return (
            <Grid key={`file-${index}`} container item>
                <Grid item xs={10}>
                    <Button
                        fullWidth
                        variant="text"
                        href={file}
                        download={getFileName(file)}
                        startIcon={ARCHIVE_EXTENSIONS.indexOf(getfileExtension(file)) !== -1 ? <FolderZipIcon fontSize="large" /> : <InsertDriveFileIcon fontSize="large" />}
                        sx={{
                            textTransform: 'none',
                            justifyContent: 'left'
                        }}
                    >
                        <Typography>{getFileName(file)}</Typography>
                    </Button>
                </Grid>
                <Grid item xs={2} sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'flex-end'
                }}>
                    {(userRole === UsersRoles.ADMIN || userRole === UsersRoles.MANAGER || userRole === UsersRoles.PROCESSOR) &&
                        <IconButton aria-label="replace" size="small" onClick={() => { setIsEditMode(true) }}>
                            <PublishedWithChangesIcon color="primary" />
                        </IconButton>
                    }
                </Grid>
            </Grid>
        )
    })

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Typography variant="h6" color='text.secondary'>{t(`ProjectCard.For completed project`)}</Typography>
                        {(isEditMode && pathToDoneFile?.length && pathToDoneFile?.length > 0) ? 
                            <IconButton aria-label="edit" size="small" onClick={() => { setIsEditMode(false) }}>
                                <CloseIcon />
                            </IconButton>:<></>
                        }
                    </Grid>
                    {isEditMode && <Grid item xs={12}>
                        <AddFiles files={files} setFiles={setFiles} autoSand={true} />
                    </Grid>}
                    {((!pathToDoneFile?.length || pathToDoneFile?.length <= 0) && userRole === UsersRoles.OPERATOR) &&
                        <Grid item xs={12} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Typography color='text.secondary'>{t(`ProjectCard.There are no ready files yet...`)}</Typography>
                        </Grid>
                    }
                    {!isEditMode && filesList}
                </Grid>
            </CardContent>
        </Card>
    );
}
