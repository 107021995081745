import * as React from "react";
import { amber, blue, cyan, deepOrange, grey, orange, red, teal, yellow } from "@mui/material/colors";
import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material/styles";
import { } from '@mui/x-data-grid/themeAugmentation';
import { AppBar } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
// ThemeOptions
export const themeConfig = (): any => {
  const darkMode = (localStorage.getItem("darkMode") === "true")
  return {
    components: {
      AppBar: {
        zIndex: 2
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderBottom: "none"
          },
          columnHeaderTitle: {
            fontWeight: 600
          },
          ...(!darkMode
            ? { // light mode
              row: {
                "&.urgent-row": {
                  backgroundColor: yellow[100]
                },
                "&.problem-row": {
                  backgroundColor: red[50]
                },
                "&.Mui-selected": {
                  backgroundColor: teal[100],
                },
                ":hover": {
                  backgroundColor: teal[50],
                },
              },
            }
            : { // dark mode
              row: {
                "&.urgent-row": {
                  backgroundColor: yellow[900],
                },
                "&.problem-row": {
                  backgroundColor: red[800]
                },
                "&.Mui-selected": {
                  backgroundColor: teal[900],
                },
                ":hover": {
                  backgroundColor: teal[800],
                },
              },
            }),
          cell: {
            ":focus": {
              outline: 'none'
            }
          }
        }
      }
    },
    palette: {
      mode: darkMode ? "dark" : "light",
      ...(!darkMode
        ? { // light mode
          primary: {
            main: teal[700],
          },
          background: {
            default: grey[50],
          },
          secondary: {
            main: blue[800]
          }
        }
        : { // dark mode
          primary: {
            main: teal[300],
          },
          secondary: {
            main: blue[800],
          }
        }),
    },
  }
}
