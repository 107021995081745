export const uatranslation = {
  sideMenu: {
    "List of projects": "Список проектів",
    "Add project": "Додати проект",
    "Modeling emails management": "Управління моделюванням електронної пошти",
    "User management": "Управління користувачами",
    "Projects data export": "Експорт даних проектів"
  },
  common: {
    Send: "Надіслати",
    projectStatus: {
      added: "Додано",
      modelling: "Моделювання",
      ready_for_assignment: "Очікує на призначення",
      processing: "Обробка",
      readyforchecking: "Очікує на контроль",
      done: "Завершено",
      archivated: "Архівовано",
      problem: "Проблема"
    },
    "total": "Загалом",
    "This field is required": "Це поле обов'язкове",
    "All Users": "Всі користувачі",
    Yes: "Так",
    No: "Ні",
    Submit: "Відправити",
    "Login": "Увійти",
    "Add email": "Додати електронну пошту",
    "Enter email": "Введіть електронну пошту",
    "Settings": "Налаштування",
    "Logout": "Вийти",
    "Change password": "Змінити пароль",
    "Dark mode": "Темний режим",
  },
  ProjectActions: {
    "Unmark as Urgent": "Відмітити як неактуальне",
    "Confirm project": "Підтвердити проект",
    "All Files": "Всі файли",
    "Image Files": "Зображення",
    "Show the page of project": "Показати сторінку проекту",
    Archivate: "Архівувати",
    "Mark as Urgent": "Позначити як терміновий",
    "Report problem with the project": "Повідомити про проблему з проектом",
    "Resolve problem": "Вирішити проблему"
  },
  AddProject: {
    "Adjustment": "Коригування",
    "Project is urgent": "Проект терміновий",
    "Pick file": "Вибрати файл",
    "Modeling emails are loading...": "Завантаження електронних листів для моделювання...",
    "Email for modelling": "Електронна пошта для моделювання",
    Name: "Назва",
    Address: "Адреса",
    GPS: "GPS",
    "Notes for modelling": "Примітки для моделювання",
    "Notes for processing": "Примітки для обробки",
  },
  ProjectCard: {
    "Project Details": "Деталі проекту",
    "For completed project": "Для завершеного проекту",
    Name: "Назва",
    Address: "Адреса",
    GPS: "GPS",
    Status: "Статус",
    Plohy: "Площа",
    "Modeling email": "Електронна пошта для моделювання",
    Assignee: "Виконавець",
    Sender: "Відправник",
    Notes: "Примітки",
    "Waiting for selected project...": "Будь ласка, виберіть проект",
    Amount: "Сума",
    "Problem description": "Опис проблеми",
    "Map link": "Посилання на карту",
    "Search at Map.cz": "Пошук на Map.cz",
    "Date": "Дата",
    "There are no ready files yet...": "Ще немає готових файлів..."
  },
  ProjectsFilters: {
    dates: {
      THREE_DAYS: "3 дні",
      ONE_DAY: "1 день",
      ONE_WEEK: "1 тиждень",
      ONE_MOTH: "1 місяць",
      ONE_YEAR: "1 рік",
    },
  },
  ProjectsTable: {
    "Not assigned": "Не призначено",
    "Processed By": "Оброблено",
    "Pick users...": "Вибрати користувачів...",
  },
  ProjectProblem: {
    "Problem description": "Опис проблеми",
    "Problem with project": "Проблема з проектом"
  },
  UsersForms: {
    Username: "Ім'я користувача",
    "First Name": "Ім'я",
    "Last Name": "Прізвище",
    Email: "Електронна пошта",
    Password: "Пароль",
    RePassword: "Повторіть пароль",
    "Working Code": "Робочий код",
    role: "Роль",
    roles: {
      admin: "Адміністратор",
      manager: "Менеджер",
      operator: "Оператор",
      processor: "Процесор",
    },
    "New user": "Новий користувач",
    "Change new password": "Змінити новий пароль",
    "Change": "Змінити",
    "Add User": "Додати користувача",
    "Are you sure that you want to delete":
      "Ви впевнені, що хочете видалити",
    "Delete user": "Видалити користувача",
    "Edit user": "Редагувати користувача",
    Actions: "Дії",
  },
  FilterForms: {
    "Start day": "Початкова дата",
    "End day": "Кінцева дата",
    projectsStatuses: "Статуси проектів",
    "Pick status...": "Оберіть статуси...",
    Sender: "Відправник",
    "Pick users...": "Вибрати користувачів...",
    Assignee: "Виконавець",
    Date: "Дата",
    "Pick date range...": "Оберіть діапазон дат...",
    "Search...": "Пошук...",
  },
  ProjectExport: {
    "Instruction": "Інструкція",
    "To generate a project file you need:": "Для створення файлу проекту потрібно:",
    "Select start date (Date on the left side) and end date (on the right side)": "Виберіть початкову та кінцеву дату.",
    "Projects are displayed including selected dates": "Проекти будуть відображені з обраними датами",
    "The starting date must be less than the final date": "Перевірте, що початкова дата раніше кінцевої",
    "You can also select other filters as you would on the project page": "Ви також можете вибрати інші фільтри, як і на сторінці проекту",
    "After selecting all the settings, click the download button and an excel file will be downloaded to your computer": "Після вибору всіх налаштувань натисніть кнопку завантаження, і файл Excel буде завантажено на ваш комп'ютер.",
    "Export Data": "Експорт даних"
  },
  Alerts: {
    "Request failed with status code 400": "Запит не вдалося виконати з кодом статусу 400",
    "Created": "Створено новий проект",
    "EmailCreated": "Створено нову пошту",
    "EmailDeleted": "Електронна пошта видалена",
    "ProjectCreated": "Проект створено!",
    "ProjectUpdated": "Проект оновлено!",
    "User Added": "Користувача додано",
    "ProjectCreatedWithoutEmail": "Проект створено, але лист для моделювання не був відправлений! Будь ласка, відправте електронний лист вручну.",
    //*************************Warnings */
    "Maximal uplad size is 4.5MB": "Максимальний розмір завантаження - 4,5 МБ",
    //*************************errors */
    "unknown error": "Невідома помилка. Будь ласка, спробуйте ще раз пізніше",
    "Project id already exists": "Ідентифікатор проекту вже існує",
    "Network Error": "Помилка мережі",
    "Request failed with status code 401": "Помилка авторизації",
    "Please set the Square amount before confirming the project!": "Будь ласка, встановіть кількість квадратів перед підтвердженням проекту!",
    "Please select processing person!": "Будь ласка, оберіть особу для обробки!",
    "Passwords has been changed": "Пароль було змінено",
    "User Updated": "Користувача оновлено",
    "Archived": "Файли проектів успішно заархівовані",
    "Archiving process failed": "Процес архівування не вдався.",
    "No projects found older than the specified months": "Помилка: Проекти, старші за вказану кількість місяців, не знайдено.",
  },
  ChangePassword: {
    "Password must be at least 8 characters long": "Пароль повинен містити принаймні 8 символів",
    "Password must contain at least one uppercase letter": "Пароль повинен містити принаймні одну велику літеру",
    "Password must contain at least one lowercase letter": "Пароль повинен містити принаймні одну малу літеру",
    "Password must contain at least one digit": "Пароль повинен містити принаймні одну цифру",
    "Passwords do not match": "Паролі не співпадають",
  },
  EmailForm: {
    "Delete email": "Видалити лист",
    "Are you sure that you want to delete email": "Ви впевнені, що хочете видалити лист",
  },
  Info :{
    "Help": "Допомога",
    "If an email fails to be sent during the project creation process, a corresponding message will be displayed.": "Якщо електронного листа не вдалося відправити під час процесу створення проекту, буде відображено відповідне повідомлення.",
    "In such a case, you can manually send the email and upload the project content on the project page": "У такому випадку ви можете вручну відправити електронний лист та завантажити вміст проекту на сторінці проекту",
    "There is a feature that allows you to prioritize a project by selecting the 'Project is urgent' action through the switch, ensuring it receives immediate attention and expedited handling": "Є функція, яка дозволяє вам пріоритезувати проект, вибираючи дію 'Проект є терміновим' через перемикач, забезпечуючи йому негайну увагу та прискорене вирішення",
    "There is an option to paste files from the clipboard (Ctrl + V)": "Є можливість вставляти файли з буфера обміну (Ctrl + V)",
    "An already created project can be marked as urgent using the respective button in the project information.": "Вже створений проект може бути відзначений як терміновий за допомогою відповідної кнопки в інформації про проект.",
    "Afterward, it will move to the top of the projects list and be highlighted with a yellow background.": "Після цього він перейде на верхню частину списку проектів і буде виділено жовтим фоном.",
    "Additionally, a feature has been added to report issues with a project using the corresponding button.": "Додатково була додана функція повідомлення про проблеми з проектом за допомогою відповідної кнопки.",
    "When a problem is reported, the project is highlighted in red.": "Коли повідомляється про проблему, проект підсвічується червоним.",
    "Problem with emails": "Проблема з електронними листами",
    "Urgent projects": "Термінові проекти",
    "Paste from clipboard": "Вставити з буфера обміну",
    "Troubled project":"Проект з проблемою",
  },
  SystemMonitor: {
    "Storage": "Сховище",
    "Archive projects": "Архівувати",
    "Free": "Вільно",
    "out of": "з",
    "GB": "ГБ",
    "Notice": "Архівувати файли проектів старші за останні ... місяці /в",
    "This process": "Процес архівування НЕ можливо перервати, підтвердіть початок!",
    "Confirm": "Підтвердити",
    "Decline": "Відхилити"
  }
};
