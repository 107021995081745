export const czTranslation = {
  sideMenu: {
    "List of projects": "Seznam projektů",
    "Add project": "Přidat projekt",
    "Modeling emails management": "Správa modelování e-mailů",
    "User management": "Správa uživatelů",
    "Projects data export": "Export dat projektů",
  },
  common: {
    Send: "Odeslat",
    projectStatus: {
      added: "Přidáno",
      modelling: "Modelování",
      ready_for_assignment: "Čeká na přiřazení",
      processing: "Zpracování",
      readyforchecking: "Čeká na kontrolu",
      done: "Hotovo",
      archivated: "Archivováno",
      problem: "Problém",
    },
    "total": "Celkový",
    "This field is required": "Toto pole je povinné",
    "All Users": "Všichni uživatelé",
    Yes: "Ano",
    No: "Ne",
    Submit: "Odeslat",
    Login: "Přihlásit se",
    "Add email": "Přidat e-mail",
    "Enter email": "Zadejte e-mail",
    Settings: "Nastavení",
    Logout: "Odhlásit se",
    "Change password": "Změnit heslo",
    "Dark mode": "Temný režim",
  },
  ProjectActions: {
    "Unmark as Urgent": "Zrušit označení jako urgentní",
    "Confirm project": "Potvrdit projekt",
    "All Files": "Všechny soubory",
    "Image Files": "Obrázkové soubory",
    "Show the page of project": "Zobrazit stránku projektu",
    Archivate: "Archivovat",
    "Mark as Urgent": "Označit jako naléhavé",
    "Report problem with the project": "Nahlásit problém s projektem",
    "Resolve problem": "Vyřešit problém",
  },
  AddProject: {
    "Adjustment": "Úprava",
    "Project is urgent": "Projekt je naléhavý",
    "Pick file": "Vybrat soubor",
    "Modeling emails are loading...": "Načítání modelovacích e-mailů...",
    "Email for modelling": "E-mail pro modelování",
    Name: "Název",
    Address: "Adresa",
    GPS: "GPS",
    "Notes for modelling": "Poznámky k modelování",
    "Notes for processing": "Poznámky k zpracování",
  },
  ProjectCard: {
    "Project Details": "Podrobnosti projektu",
    "For completed project": "Pro dokončený projekt",
    Name: "Název",
    Address: "Adresa",
    GPS: "GPS",
    Status: "Stav",
    Plohy: "Plocha",
    "Modeling email": "Modelovací e-mail",
    Assignee: "Zadavatel",
    Sender: "Odesílatel",
    Notes: "Poznámky",
    "Waiting for selected project...": "Vyberte prosím projekt",
    Amount: "Částka",
    "Problem description": "Popis problému",
    "Map link": "Odkaz na mapu",
    "Search at Map.cz": "Hledat na Map.cz",
    Date: "Datum",
    "There are no ready files yet...": "Zatím nejsou žádné hotové soubory...",
  },
  ProjectsFilters: {
    dates: {
      THREE_DAYS: "3 dny",
      ONE_DAY: "1 den",
      ONE_WEEK: "1 týden",
      ONE_MOTH: "1 měsíc",
      ONE_YEAR: "1 rok",
    },
  },
  ProjectsTable: {
    "Not assigned": "Nepřiřazeno",
    "Processed By": "Zpracováno",
    "Pick users...": "Vybrat uživatele...",
  },
  ProjectProblem: {
    "Problem description": "Popis problému",
    "Problem with project": "Problém s projektem",
  },
  UsersForms: {
    Username: "Uživatelské jméno",
    "First Name": "Jméno",
    "Last Name": "Příjmení",
    Email: "E-mail",
    Password: "Heslo",
    RePassword: "Znovu zadat heslo",
    "Working Code": "Pracovní kód",
    role: "Role",
    roles: {
      admin: "Admin",
      manager: "Manažer",
      operator: "Operátor",
      processor: "Procesor",
    },
    "New user": "Nový uživatel",
    "Change new password": "Změnit heslo",
    Change: "Změnit",
    "Add User": "Přidat uživatele",
    "Are you sure that you want to delete": "Opravdu chcete smazat",
    "Delete user": "Smazat uživatele",
    "Edit user": "Upravit uživatele",
    Actions: "Akce",
  },
  FilterForms: {
    "Start day": "Počáteční datum",
    "End day": "Koncový den",
    projectsStatuses: "Stav projekty",
    "Pick status...": "Vyberte stavy...",
    Sender: "Odesílatel",
    "Pick users...": "Vyberte uživatele...",
    Assignee: "Zadavatel",
    Date: "Datum",
    "Pick date range...": "Vyberte rozsah dat...",
    "Search...": "Hledat...",
  },
  ProjectExport: {
    Instruction: "Instrukce",
    "To generate a project file you need:":
      "K vytvoření souboru projektu potřebujete:",
    "Select start date (Date on the left side) and end date (on the right side)":
      "Vyberte počáteční a koncové datum",
    "Projects are displayed including selected dates":
      "Projekty budou zobrazeny včetně vybraných dat",
    "The starting date must be less than the final date":
      "Zkontrolujte, že začáteční datum je dříve než koncové datum",
    "You can also select other filters as you would on the project page":
      "Můžete také vybrat další filtry, jako na stránce s projekty",
    "After selecting all the settings, click the download button and an excel file will be downloaded to your computer":
      "Po vybrání všech nastavení klikněte na tlačítko stáhnout a soubor Excel bude stažen na váš počítač",
    "Export Data": "Exportovat data",
  },
  Alerts: {
    "Request failed with status code 400": "Žádost selhala s kódem stavu 400",
    Created: "Vytvořen nový projekt",
    EmailCreated: "Vytvořen nový e-mail",
    "User Added": "Uživatel přidán",
    EmailDeleted: "E-mail smazán",
    ProjectCreated: "Projekt vytvořen!",
    ProjectUpdated: "Projekt aktualizován!",
    ProjectCreatedWithoutEmail:
      "Projekt vytvořen, ale e-mail pro modelování nebyl odeslán! Prosím, odešlete e-mail ručně.",
    //*************************Warnings */
    "Maximal uplad size is 4.5MB": "Maximální velikost nahrávání je 4.5MB",
    //*************************errors */
    "unknown error": "Neznámá chyba. Zkuste to prosím později znovu",
    "Project id already exists": "ID projektu již existuje",
    "Network Error": "Chyba sítě",
    "Request failed with status code 401": "Chyba autorizace",
    "Please set the Square amount before confirming the project!": "Nastavte prosím počet čtverců před potvrzením projektu!",
    "Please select processing person!": "Vyberte zpracovatele!",
    "Passwords has been changed": "Hesla byla změněna",
    "User Updated": "Uživatel aktualizován",
    "Archived": "Projektové soubory byly úspěšně archivovány",
    "Archiving process failed": "Proces archivace selhal.",
    "No projects found older than the specified months": "Chyba: Nebyly nalezeny projekty starší než zadaný počet měsíců.",
  },
  ChangePassword: {
    "Password must be at least 8 characters long":
      "Heslo musí být alespoň 8 znaků dlouhé",
    "Password must contain at least one uppercase letter":
      "Heslo musí obsahovat alespoň jedno velké písmeno",
    "Password must contain at least one lowercase letter":
      "Heslo musí obsahovat alespoň jedno malé písmeno",
    "Password must contain at least one digit":
      "Heslo musí obsahovat alespoň jednu číslici",
    "Passwords do not match": "Hesla se neshodují",
  },
  EmailForm: {
    "Delete email": "Smazat e-mail",
    "Are you sure that you want to delete email":
      "Opravdu chcete smazat e-mail",
  },
  Info: {
    Help: "Nápověda",
    "If an email fails to be sent during the project creation process, a corresponding message will be displayed.":
      "Pokud selže odeslání e-mailu během procesu vytváření projektu, zobrazí se odpovídající zpráva.",
    "In such a case, you can manually send the email and upload the project content on the project page":
      "V takovém případě můžete ručně odeslat e-mail a nahrát obsah projektu na stránce projektu",
    "There is a feature that allows you to prioritize a project by selecting the 'Project is urgent' action through the switch, ensuring it receives immediate attention and expedited handling":
      "Existuje funkce, která vám umožňuje prioritizovat projekt výběrem akce „Projekt je naléhavý“ přes přepínač, zajistí tak okamžitou pozornost a urychlené zpracování",
    "There is an option to paste files from the clipboard (Ctrl + V)":
      "Existuje možnost vložit soubory z clipboardu (Ctrl + V)",
    "An already created project can be marked as urgent using the respective button in the project information.":
      "Již vytvořený projekt lze označit jako naléhavý pomocí příslušného tlačítka v informacích o projektu.",
    "Afterward, it will move to the top of the projects list and be highlighted with a yellow background.":
      "Následně se přesune na vrchol seznamu projektů a bude zvýrazněn žlutým pozadím.",
    "Additionally, a feature has been added to report issues with a project using the corresponding button.":
      "Navíc byla přidána funkce nahlásit problémy s projektem pomocí příslušného tlačítka.",
    "When a problem is reported, the project is highlighted in red.":
      "Když je problém nahlášen, projekt je zvýrazněn červeně.",
    "Problem with emails": "Problém s e-maily",
    "Urgent projects": "Naléhavé projekty",
    "Paste from clipboard": "Vložit z clipboardu",
    "Troubled project": "Projekt s problémem",
  },
  SystemMonitor: {
    "Storage": "Úložiště",
    "Archive projects": "Archivovat",
    "Free": "Volných",
    "out of": "z",
    "GB": "GB",
    "Notice": "Archivovat soubory projektů starší než posledních ... měsíců",
    "This process": "Proces archivace nelze přerušit, potvrďte začátek!",
    "Confirm": "Potvrdit",
    "Decline": "Odmítnout"
  }
};
