import Button from "@mui/material/Button";
import { EmailFrontDto } from "../../_types";
import { emailsActions, useAppDispatch } from "../../_store";
import { CustomDialog } from "../CustomDialog";
import { useTranslation } from "react-i18next";

export function DeleteEmailForm(props: { open: boolean, onClose: Function, email: EmailFrontDto, email_id: number | null }) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleDelete = async (email: number | null) => {
    if (email) {
      dispatch(emailsActions.deleteEmail(email));
      props.onClose();
    } else {
      throw new Error("Empty EMAIL");
    }
  };

  return (
    <CustomDialog
      dialogTitle={t("EmailForm.Delete email")}
      dialogContent={(<>{t("EmailForm.Are you sure that you want to delete email")} : <strong>{props.email.email}</strong>?</>)}
      dialogActions={
        <>
          <Button
            variant="outlined"
            onClick={() => props.onClose()}
          >
            {t("common.No")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(props.email_id)}
          >
            {t("common.Yes")}
          </Button>
        </>
      }
      open={props.open}
      onClose={props.onClose}
    />
  );
}