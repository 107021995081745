import { Grid } from '@mui/material';
import { MainContainer, MainDrawer } from '../_components';
import { ProjectsTable } from '../_components/projectsComponents/ProjectTable/ProjectsTable';
import { ProjectsCard } from '../_components/projectsComponents/ProjectsCard';
import { ProjectsFilters } from '../_components/projectsComponents/ProjectsFilters';
import { ProjectStatisticBar } from '../_components/projectsComponents/ProjectStatisticBar';

export function ProjectManagement() {

  return (
    <>
      <MainContainer
        drawer={<MainDrawer />}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProjectsFilters />
          </Grid>
          <Grid item xs={12} xl={6}>
            <ProjectsTable />
          </Grid>
          <Grid item xs={12} xl={6}>
            <ProjectsCard />
          </Grid>
        </Grid>
      </MainContainer>
    </>
  )
}
