import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import './App.css';

import { history } from './_helpers';
import { ProjectManagement, AddProject, Event, EmailContainer, UserManagement, Login, Info } from './_containers';
import { ProjectDataExport } from './_containers/ProjectDataExport';

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <Login>
      <Routes>
        <Route path="/project-management" element={<ProjectManagement />} />
        <Route path="/info" element={<Info />} />
        <Route path="/addproject" element={<AddProject />} />
        <Route path="/modeling-emails-management" element={<EmailContainer />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/project-export" element={<ProjectDataExport />} />
        <Route path="*" element={<Navigate to="/project-management" />} />
      </Routes>
    </Login>
  )
}

export default App;
