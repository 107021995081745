import { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { DeleteEmailForm } from "../emailComponents/DeleteEmailForm";

import { useAppDispatch, useAppSelector, emailsActions } from "../../_store";

import { useTranslation } from "react-i18next";
import { Card, CardContent } from "@mui/material";
import { EmailFrontDto } from "../../_types";

export function EmailList() {
  const dispatch = useAppDispatch();
  const emails = useAppSelector((store) => store.email.emails);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<EmailFrontDto>({
    email: "",
  });
  const [selectedEmailId, setSelectedEmailId] = useState<number | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(emailsActions.getAllEmails());
  }, [dispatch]);

  function deleteOpenHandler(email: EmailFrontDto, email_id: number| null ) {
    setSelectedEmail(email);
    setSelectedEmailId(email_id);
    setDeleteModalIsOpen(true);
  }

  return (
    <Card sx={{ width: "100%", display: "flex" }}>
      <DeleteEmailForm
        open={deleteModalIsOpen}
        onClose={() => setDeleteModalIsOpen(false)}
        email={selectedEmail}
        email_id={selectedEmailId}
      />
      <CardContent sx={{ flex: 1 }}>
        <List>
          {emails.map((email) => (
            <ListItem key={email.id}>
              <ListItemText primary={email.email} />
              <IconButton edge="end" onClick={() => deleteOpenHandler(email, email.id)}>
                <CancelIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
