import { useEffect, useState } from "react";
import { MainContainer, MainDrawer } from ".";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { usersActions, useAppDispatch, useAppSelector } from "../_store";

import { UpdateUserDto } from "../_types";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { themeConfig } from "./template/createCentralizedTheme";
import { createTheme } from "@mui/material/styles";
import { AddUserForm } from "./userComponents/AddUserForm";
import { UpdateUserForm } from "./userComponents/UpdateUserForm";
import { DeleteUserForm } from "./userComponents/DeleteUserForm";
import { UsersRoles } from "../_constants/UsersRoles";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GetDataGridLocalization } from "../_localizations/ComponetsLocalizations.helper";

export function AddUserComponent() {
  const dispatch = useAppDispatch();
  const theme = createTheme(themeConfig());

  useEffect(() => {
    dispatch(usersActions.getAllUsers());
  }, [dispatch]);

  const users = useAppSelector((state) => state.users.users);

  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const { t } = useTranslation();

  const DataGridLocale = GetDataGridLocalization();

  const [selectedUser, setSelectedUser] = useState<UpdateUserDto>({
    id: "",
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    workingCode: "",
    role: UsersRoles.PROCESSOR,
    userThatCheck: null,
  });

  const userInProcess = useAppSelector(
    (store) => store.users.loaders.userProcessing
  );

  function updateOpenHandler(user: UpdateUserDto) {
    setSelectedUser(user);
    setEditModalIsOpen(true);
  };

  function deleteOpenHandler(user: UpdateUserDto) {
    setSelectedUser(user);
    setDeleteModalIsOpen(true);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "userName",
      flex: 4,
      headerName: t(`UsersForms.Username`),
    },
    {
      field: "firstName",
      flex: 4,
      headerName: t(`UsersForms.First Name`),
    },
    {
      field: "lastName",
      flex: 4,
      headerName: t(`UsersForms.Last Name`),
    },
    {
      field: "email",
      flex: 4,
      headerName: t(`UsersForms.Email`),
    },
    {
      field: "role",
      flex: 4,
      headerName: t(`UsersForms.role`),
      renderCell: (params) => t(`UsersForms.roles.${params.value}`)
    },
    {
      field: "actions",
      flex: 2,
      headerName: t(`UsersForms.Actions`),
      cellClassName: "pl-0",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              style={{marginRight:"0px"}}
              edge="end"
              onClick={() => {
                updateOpenHandler(params.row);
              }}
            >
              <EditNoteIcon />
            </IconButton>
            <IconButton
              edge="end"
              onClick={() => deleteOpenHandler(params.row)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <MainContainer globalLoader={userInProcess} drawer={<MainDrawer />}>
      <>
      <Grid container spacing={0}>
        <AddUserForm
          open={addModalIsOpen}
          onClose={() => setAddModalIsOpen(false)}
        />
        <UpdateUserForm
          open={editModalIsOpen}
          onClose={() => setEditModalIsOpen(false)}
          user={selectedUser}
        />
        <DeleteUserForm
          open={deleteModalIsOpen}
          onClose={() => setDeleteModalIsOpen(false)}
          user={selectedUser}
        />
        <Card sx={{ width: "100%" }}>
          <CardContent sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6">{t("common.All Users")}</Typography>
              <IconButton onClick={() => setAddModalIsOpen(true)}>
                <PersonAddIcon
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                />
              </IconButton>
            </Box>
            <DataGrid
              disableColumnMenu
              rowSelection={false}
              localeText={DataGridLocale}
              columnVisibilityModel={{
                id: false,
              }}
              rows={users}
              columns={columns}
            />
          </CardContent>
        </Card>
      </Grid>
      </>
    </MainContainer>
  );
}
