import { red, indigo, yellow, cyan, green, grey, blue, brown } from '@mui/material/colors';

export enum ProjectStatuses {
    ADDED = 'added',
    MODELLING = 'modelling',
    READY_FOR_ASSIGNMENT = 'ready_for_assignment',
    PROCESSING = 'processing',
    READY_FOR_CHECKING = 'readyforchecking',
    DONE = 'done',
    ARCHIVATED = 'archivated',
    PROBLEM = 'problem'
}

export const ProjectStatusesColors = {
    added: brown[800],
    modelling: brown[800],
    ready_for_assignment: blue[800],
    processing: cyan[700],
    readyforchecking: yellow[800],
    done: green[800],
    archivated: grey[500],
    problem: red[800]
}