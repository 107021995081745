// Import statements
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import {
  Fade,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material";

// Redux store imports
import { useAppDispatch, useAppSelector, loginActions } from "../_store";
import { AlertComponent } from "./alertComponents/AlertComponent";

// Function to create theme config
import { themeConfig } from "./template/createCentralizedTheme";
import { useTranslation } from "react-i18next";
import { sha3_512 } from "js-sha3";

export function LoginComponent() {
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const logInLoading = useAppSelector(
    (store) => store.login.loaders.logInLoading
  );

  const [errors, setErrors] = useState({
    username: false,
    password: false,
  });

  const theme = createTheme(themeConfig());

  const handleLogin = () => {
    const validationErrors = {
      username: !username.trim(),
      password: !password.trim(),
    };
    if (!Object.values(validationErrors).some((error) => error)) {

      let hashedPassword: string = sha3_512(password)

      dispatch(
        loginActions.logIn({
          username: username, // Adjust the property name to match your API payload
          password: hashedPassword,
        })
      );
    }
    setErrors(validationErrors);
  };

  const hideAlertHandler = () => {
    dispatch(loginActions.hideAlert());
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Fade in={logInLoading} unmountOnExit>
        <LinearProgress color="primary" />
      </Fade>
      <AlertComponent />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "90vh" }}
      >
        <Grid item>
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            label="Username"
            variant="outlined"
            margin="normal"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={errors.username}
            helperText={errors.username && "Username is required"}
            onKeyDown={handleKeyPress}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errors.password}
            helperText={errors.password && "Password is required"}
            onKeyDown={handleKeyPress}
          />
          {error && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{error}</Alert>
            </Stack>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            style={{ marginTop: "20px", float: "right" }}
            disabled={logInLoading}
          >
            {t("common.Login")}
          </Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
