import { Alert, Collapse, createTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
  alertActions,
} from "../../_store";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { themeConfig } from "../template/createCentralizedTheme";

export function AlertComponent() {
  const showAlert = useAppSelector((state) => state.alert.alert);
  const dispatch = useAppDispatch();
  const [isAnimating, setIsAnimating] = useState(false);

  const { t } = useTranslation();
  const theme = createTheme(themeConfig());
  const alertRef: MutableRefObject<HTMLDivElement | null> = useRef(null)

  return (
    <Collapse
      ref={alertRef}
      in={showAlert !== null && !isAnimating}
      style={{ width: "100%" }}
      onEntering={() => {
        dispatch(alertActions.setGlobalAlertHeight(72))
      }}
      onEntered={() => {
        dispatch(alertActions.setGlobalAlertHeight(alertRef.current?.offsetHeight || 0))
      }}
      unmountOnExit
      onExiting={() => {
        dispatch(alertActions.setGlobalAlertHeight(0))
      }}
      onExited={() => {
        dispatch(alertActions.clearState())
        setIsAnimating(false)
      }}
    >
      <Alert
        severity={showAlert?.type}
        onClose={() => { setIsAnimating(true) }}
        sx={{ marginBottom: theme.spacing(3) }}
      >
        {t(`Alerts.${showAlert?.message}`)}
      </Alert>
    </Collapse>
  );
}
