import { useEffect } from "react";
import { useAppSelector, projectActions, useAppDispatch } from "../../_store";
import { DataGrid, GridColDef, GridEventListener, GridFooter, GridValueGetterParams, useGridApiContext, useGridApiEventHandler } from '@mui/x-data-grid';
import { Alert, Autocomplete, Box, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import React from "react";
import { useTranslation } from "react-i18next";
import { ProjectStatusesColors } from "../../_constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import { makeEndDate, makeStartDate } from "../../_helpers/date.halper";


export function ProjectStatisticBar() {
    const { t } = useTranslation();
    const statistics = useAppSelector((store) => store.project.statistics);

    return (
        <Grid container sx={{justifyContent: 'center', mt: 1.5}}>
            <Typography variant="subtitle1" color="text.secondary">
                <strong style={{ color: ProjectStatusesColors['modelling'] }}>{t(`common.projectStatus.modelling`)}: </strong>
                {statistics?.modeling} |
                <strong style={{ color: ProjectStatusesColors['ready_for_assignment'], marginLeft: 4 }}>{t(`common.projectStatus.ready_for_assignment`)}: </strong>
                {statistics?.readyForAssignment} |
                <strong style={{ color: ProjectStatusesColors['processing'], marginLeft: 4  }}>{t(`common.projectStatus.processing`)}: </strong>
                {statistics?.processing} |
                <strong style={{ color: ProjectStatusesColors['readyforchecking'], marginLeft: 4  }}>{t(`common.projectStatus.readyforchecking`)}: </strong>
                {statistics?.readyForChecking} |
                <strong style={{ color: ProjectStatusesColors['done'], marginLeft: 4  }}>{t(`common.projectStatus.done`)}: </strong>
                {statistics?.done} |
                <strong style={{ marginLeft: 4 }}>{t(`common.total`)}: </strong>
                {statistics?.total}
            </Typography>
        </Grid>
    );
}
