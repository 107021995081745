export const API_ENDPOINTS = {
    project: '/project',
    users: '/users',
    emails: '/emails',
    auth: '/auth',
    files: '/project/files',
    deleteFile: '/project/deleteFile',
    changePassword: '/users/changePassword',
    systemMonitor: '/space-manager'
} as const
console.log(process.env.NODE_ENV)
export const API_SERVER_URL = process.env.NODE_ENV === 'production'? "https://roofsmanager.com/api/" : "https://roofsmanager.com/api/"