import { useAppSelector, projectActions, useAppDispatch } from "../../_store";
import { Button, Grid } from "@mui/material";
import React from "react";
import { Project } from "../../_types";
import { useTranslation } from "react-i18next";
import { ProjectStatuses } from "../../_constants/ProjectStatuses";
import { ProjectProblemDialog } from "./ProjectProblemDialog";
import { UsersRoles } from "../../_constants";
import ReportIcon from '@mui/icons-material/Report';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import ArchiveIcon from '@mui/icons-material/Archive';
import AlarmIcon from '@mui/icons-material/Alarm';
import TaskIcon from '@mui/icons-material/Task';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';

export function ProjectActions() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedProject: Project = useAppSelector((store) => store.project.selectedProject);
    const selectedProjectIsLoading = useAppSelector((store) => store.project.loaders.selectedProjectIsLoading);
    const [reportProblemIsOpen, setReportProblemIsOpen] = React.useState(false);
    const userRole = useAppSelector((store) => store.login.JWTtoken?.role)

    return (
        <>
            <Grid container spacing={2} sx={{ justifyContent: "flex-end" }} >
                {(userRole === UsersRoles.ADMIN || userRole === UsersRoles.OPERATOR) &&
                    <>
                        {
                            selectedProject.isUrgent ?
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        disabled={selectedProjectIsLoading}
                                        startIcon={<AlarmOffIcon />}
                                        variant="outlined"
                                        onClick={() => {
                                            dispatch(projectActions.updateProject({ id: selectedProject.id, isUrgent: false }))
                                        }}>{t("ProjectActions.Unmark as Urgent")}</Button>
                                </Grid>
                                :
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        disabled={selectedProjectIsLoading}
                                        startIcon={<AlarmIcon />}
                                        variant="outlined"
                                        onClick={() => {
                                            dispatch(projectActions.updateProject({ id: selectedProject.id, isUrgent: true }))
                                        }}>{t("ProjectActions.Mark as Urgent")}</Button>
                                </Grid>
                        }
                    </>
                }
                {((userRole === UsersRoles.ADMIN || userRole === UsersRoles.MANAGER) && !selectedProject.problemDescription) &&
                    <Grid item xs={6}>
                        <ProjectProblemDialog
                            open={reportProblemIsOpen}
                            onClose={() => setReportProblemIsOpen(false)}
                        />
                        <Button
                            fullWidth
                            disabled={selectedProjectIsLoading}
                            variant="outlined"
                            startIcon={<ReportIcon />}
                            onClick={() => {
                                setReportProblemIsOpen(true)
                            }}>{t("ProjectActions.Report problem with the project")}</Button>
                    </Grid>
                }
                {((userRole === UsersRoles.ADMIN || userRole === UsersRoles.MANAGER || userRole === UsersRoles.OPERATOR) && selectedProject.problemDescription) &&
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            disabled={selectedProjectIsLoading}
                            startIcon={<ReportOffIcon />}
                            variant="outlined"
                            onClick={() => {
                                dispatch(projectActions.updateProject({ id: selectedProject.id, status: ProjectStatuses.READY_FOR_ASSIGNMENT, problemDescription: "CLEAN" }))
                            }}>{t("ProjectActions.Resolve problem")}</Button>
                    </Grid>
                }
                {(userRole === UsersRoles.ADMIN || userRole === UsersRoles.OPERATOR) &&
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            disabled={selectedProjectIsLoading}
                            startIcon={<ArchiveIcon />}
                            variant="contained"
                            onClick={() => {
                                dispatch(projectActions.updateProject({ id: selectedProject.id, status: ProjectStatuses.ARCHIVATED }))
                            }}>{t("ProjectActions.Archivate")}</Button>
                    </Grid>
                }
                {(userRole === UsersRoles.ADMIN || userRole === UsersRoles.MANAGER) &&
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            disabled={selectedProjectIsLoading}
                            variant="contained"
                            startIcon={<TaskIcon />}
                            onClick={() => {
                                dispatch(projectActions.updateProject({ id: selectedProject.id, status: ProjectStatuses.DONE }))
                            }}>{t("ProjectActions.Confirm project")}</Button>
                    </Grid>
                }
            </Grid>
        </>
    );
}
