import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { MainContainer, MainDrawer } from "../_components";
import { Card, CardContent, Divider, Grid } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&::before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export function Info() {
    const { t } = useTranslation();

    const [expanded, setExpanded] = React.useState<string | false>("panel1");

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <>
            <MainContainer drawer={<MainDrawer />}>
                <>
                    <Grid container spacing={2}>
                        <Grid item xl={7} sx={{ margin: "auto" }} xs={12}>
                            <Card sx={{ width: "100%", display: "flex" }}>
                                <CardContent sx={{ flex: 1, m: 2 }}>
                                    <Typography variant="h4" gutterBottom>
                                        {t("Info.Help")}
                                    </Typography>

                                    <Accordion
                                        expanded={expanded === "panel1"}
                                        onChange={handleChange("panel1")}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                        >
                                            <Typography>{t("sideMenu.Add project")}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="h6" gutterBottom>
                                                {t("Info.Problem with emails")}
                                            </Typography>
                                            <Typography>
                                                {t(
                                                    "Info.If an email fails to be sent during the project creation process, a corresponding message will be displayed."
                                                )}
                                                <br />
                                                <img src="assets/emailError.png" />
                                                <br />
                                                {t(
                                                    "Info.In such a case, you can manually send the email and upload the project content on the project page"
                                                )}
                                            </Typography>
                                            <Divider sx={{ my: 3 }} />
                                            <Typography variant="h6" gutterBottom>
                                                {t("Info.Urgent projects")}
                                            </Typography>
                                            <Typography>
                                                {t(
                                                    "Info.There is a feature that allows you to prioritize a project by selecting the 'Project is urgent' action through the switch, ensuring it receives immediate attention and expedited handling"
                                                )}
                                                <br />
                                                <img src="assets/urgent.png" />
                                                <br />
                                            </Typography>
                                            <Divider sx={{ my: 3 }} />
                                            <Typography variant="h6" gutterBottom>
                                                {t("Info.Paste from clipboard")}
                                            </Typography>
                                            <Typography>
                                                {t(
                                                    "Info.There is an option to paste files from the clipboard (Ctrl + V)"
                                                )}
                                                <br />
                                                <img src="assets/clipB.png" />
                                                <br />
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel2"}
                                        onChange={handleChange("panel2")}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel2d-content"
                                            id="panel2d-header"
                                        >
                                            <Typography>{t("sideMenu.List of projects")}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="h6" gutterBottom>
                                                {t("Info.Urgent projects")}
                                            </Typography>
                                            <Typography>
                                                {t(
                                                    "Info.An already created project can be marked as urgent using the respective button in the project information."
                                                )}
                                                <br />
                                                <br />
                                                <img src="assets/urgentBtn.png" />
                                                <br />
                                                <br />
                                                {t(
                                                    "Info.Afterward, it will move to the top of the projects list and be highlighted with a yellow background."
                                                )}
                                                <br />
                                                <br />
                                                <img src="assets/urgentUrgentList.png" />
                                            </Typography>
                                            <Divider light sx={{ m: 2 }} />
                                            <Typography variant="h6" gutterBottom>
                                                {t("Info.Troubled project")}
                                            </Typography>
                                            <Typography>
                                                {t("Info.Additionally, a feature has been added to report issues with a project using the corresponding button.")}
                                                <br />
                                                <br />
                                                <img src="assets/problemBtn.png" />
                                                <br />
                                                <br />
                                                {t("Info.When a problem is reported, the project is highlighted in red.")}
                                                <br />
                                                <br />
                                                <img src="assets/urgentProblemList.png" />
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </CardContent>
                            </Card>
                            <></>
                        </Grid>
                    </Grid>
                </>
            </MainContainer>
        </>
    );
}
