import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { enTranslation } from "./en";
import { uatranslation } from "./ua";
import { czTranslation } from "./cz";
const lang = localStorage.getItem("language")

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      ua: {
        translation: uatranslation
      },
      cz: {
        translation: czTranslation
      }
    },
    lng: lang?lang:'en', // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });