// Import necessary modules and types
import {
  createAsyncThunk,
  createSlice,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";

import { apiClient } from "../_helpers";
import { ChangePassword, CreateUserDto, UpdateUserDto } from "../_types";
import { RootState } from ".";
import { API_ENDPOINTS } from "../_constants";
import { alertActions } from "../_store";
import { generateAlert } from "../_helpers/alertGeneration.helper";

// Create a name for the slice
const name = "users";

// Create the initial state for the users slice
const initialState = createInitialState();

// Create extra reducers and actions
const extraReducers = createExtraReducers();

type AlertType = "error" | "info" | "success" | "warning";

// Create the slice
const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers,
});

// Create extra actions for users
const extraActions = createExtraActions();

// Exports
export const usersActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// Implementation
interface UsersSlice {
  users: UpdateUserDto[];
  alert: {
    type: AlertType;
    message: string;
  } | null;
  loaders: {
    userProcessing: boolean;
  };
}

// Function to create the initial state
function createInitialState() {
  const initialState: UsersSlice = {
    users: [],
    alert: null,
    loaders: {
      userProcessing: false,
    },
  };
  return initialState;
}

// Function to create extra actions for users
function createExtraActions() {
  return {
    getAllUsers: getAllUsers(),
    deleteUser: deleteUser(),
    addUser: addUser(),
    changePassword: changePassword(),
    updateUser: updateUser(),
  };

  function changePassword() {
    return createAsyncThunk<void, ChangePassword, { state: RootState }>(
      `${name}/changePassword`,
      async (data, thunkAPI) => {
        const state = thunkAPI.getState();
        try {
          const response = await apiClient.post<ChangePassword>(
            API_ENDPOINTS.changePassword,
            data
          );
          if (response.status === 201) {
            thunkAPI.dispatch(
              alertActions.setMessage({
                alert: { type: "success", message: "Passwords has been changed" },
              })
            );
            thunkAPI.dispatch(usersActions.getAllUsers());
          } else {
            throw new Error("Cannot add user to external API");
          }
        } catch (error: any) {
          const alert = {
            type: "error" as AlertType,
            message: error.message,
          };
          thunkAPI.dispatch(alertActions.setMessage({ alert }));
          console.error("Error adding user:", error);
          throw error;
        }
      }
    );
  }

  function updateUser() {
    return createAsyncThunk<void, UpdateUserDto | null, { state: RootState }>(
      `${name}/updateUser`,
      async (createUserDto, thunkAPI) => {
        try {
          if (createUserDto) {
            const response = await apiClient.put<UpdateUserDto>(
              `${API_ENDPOINTS.users}/${createUserDto.id}`,
              createUserDto
            );
            if (response.status === 200) {
              thunkAPI.dispatch(
                alertActions.setMessage({
                  alert: { type: "success", message: "User Updated" },
                })
              );
              thunkAPI.dispatch(usersActions.getAllUsers());
            } else {
              throw new Error("Cannot update user in external API");
            }
          } else {
            throw new Error("Empty USER");
          }
        } catch (error: any) {
          thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
          throw error;
        }
      }
    );
  }

  // Function to get all users
  function getAllUsers() {
    return createAsyncThunk<UpdateUserDto[], void, { state: RootState }>(
      `${name}/getAllUsers`,
      async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        try {
          const response = await apiClient.get<UpdateUserDto[]>(
            API_ENDPOINTS.users
          );

          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error("Cannot process query to external API");
          }
        } catch (error: any) {
          thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
          throw error;
        }
      }
    );
  }

  // Function to add a new user
  function addUser() {
    return createAsyncThunk<void, CreateUserDto, { state: RootState }>(
      `${name}/addUser`,
      async (newUser, thunkAPI) => {
        const state = thunkAPI.getState();
        try {
          const response = await apiClient.post<CreateUserDto>(
            API_ENDPOINTS.users,
            newUser
          );
          if (response.status === 201) {
            thunkAPI.dispatch(
              alertActions.setMessage({
                alert: { type: "success", message: "User Added" },
              })
            );
            thunkAPI.dispatch(usersActions.getAllUsers());
          } else {
            throw new Error("Cannot add user to external API");
          }
        } catch (error: any) {
          thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
          throw error;
        }
      }
    );
  }

  // Function to delete a user
  function deleteUser() {
    return createAsyncThunk<void, string, { state: RootState }>(
      `${name}/deleteUser`,
      async (userId, thunkAPI) => {
        try {
          const response = await apiClient.delete(
            `${API_ENDPOINTS.users}/${userId}`
          );
          if (response.status === 200) {
            thunkAPI.dispatch(
              alertActions.setMessage({
                alert: { type: "success", message: "User Deleted" },
              })
            );
            thunkAPI.dispatch(usersActions.getAllUsers());
          } else {
            throw new Error("Cannot delete user from external API");
          }
        } catch (error: any) {
          thunkAPI.dispatch(alertActions.setMessage(generateAlert(error)));
          throw error;
        }
      }
    );
  }
}

// Function to create extra reducers for users
function createExtraReducers() {
  return (builder: ActionReducerMapBuilder<typeof initialState>) => {
    getAllUsers();
    deleteUser();
    addUser();
    changePassword();
    updateUser();

    function updateUser() {
      var { pending, fulfilled, rejected } = extraActions.updateUser;
      builder
        .addCase(pending, (state, action) => {
          state.loaders.userProcessing = true;
        })
        .addCase(fulfilled, (state, action) => {
          state.loaders.userProcessing = false;
        })
        .addCase(rejected, (state, action) => {
          state.loaders.userProcessing = false;
        });
    }

    function getAllUsers() {
      var { pending, fulfilled, rejected } = extraActions.getAllUsers;
      builder
        .addCase(pending, (state, action) => {
          state.loaders.userProcessing = true;
        })
        .addCase(fulfilled, (state, action) => {
          state.loaders.userProcessing = false;
          state.users = action.payload;
        })
        .addCase(rejected, (state, action) => {
          state.loaders.userProcessing = false;
        });
    }

    function deleteUser() {
      var { pending, fulfilled, rejected } = extraActions.deleteUser;
      builder
        .addCase(pending, (state, action) => {
          state.loaders.userProcessing = true;
        })
        .addCase(fulfilled, (state, action) => {
          state.loaders.userProcessing = false;
        })
        .addCase(rejected, (state, action) => {
          state.loaders.userProcessing = false;
        });
    }

    function addUser() {
      var { pending, fulfilled, rejected } = extraActions.addUser;
      builder
        .addCase(pending, (state, action) => {
          state.loaders.userProcessing = true;
        })
        .addCase(fulfilled, (state, action) => {
          state.loaders.userProcessing = false;
        })
        .addCase(rejected, (state, action) => {
          state.loaders.userProcessing = false;
        });
    }

    function changePassword() {
      var { pending, fulfilled, rejected } = extraActions.changePassword;
      builder
        .addCase(pending, (state, action) => {
          state.loaders.userProcessing = true;
        })
        .addCase(fulfilled, (state, action) => {
          state.loaders.userProcessing = false;
        })
        .addCase(rejected, (state, action) => {
          state.loaders.userProcessing = false;
        });
    }
  };
}
