import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { UsersRoles } from "../../_constants/UsersRoles";
import { UpdateUserDto } from "../../_types";
import { SelectChangeEvent } from "@mui/material/Select";
import { usersActions, useAppDispatch } from "../../_store";
import { CustomDialog } from "../CustomDialog";
import { useTranslation } from "react-i18next";
import { sha3_512 } from 'js-sha3';
import { passwordValidator } from "../../_helpers";

export function UpdateUserForm(props: {
  user: UpdateUserDto;
  open: boolean;
  onClose: Function
}) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [selectedUser, setSelectedUser] = useState<UpdateUserDto>(
    {
      id: props.user?.id || "",
      userName: props.user?.userName || "",
      firstName: props.user?.firstName || "",
      lastName: props.user?.lastName || "",
      email: props.user?.email || "",
      password: "",
      workingCode: props.user?.workingCode || "",
      role: props.user?.role || UsersRoles.PROCESSOR,
      userThatCheck: props.user?.userThatCheck || "",
    }
  );

  const [errorsPassword, setErrorsPassword] = useState(
    {
      text: "",
      alert: false
    });
  
  useEffect(() => {
    setSelectedUser(props.user);
  }, [props.user]);

  const [errors, setErrors] = useState({
    userName: false,
    firstName: false,
    email: false,
    workingCode: false,
    role: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

      setSelectedUser((prevUser) => ({
        ...(prevUser as UpdateUserDto),
        [name]: value,
      }));
  };

  const handleSelectChange = (e: SelectChangeEvent<UsersRoles>) => {
    setSelectedUser((prevUser) => ({
        ...(prevUser as UpdateUserDto),
        role: e.target.value as UsersRoles,
      }));
  };

  function sendUpdatedUser(password: string){
    dispatch(
      usersActions.updateUser({
        id: selectedUser?.id || "",
        userName: selectedUser?.userName || "",
        firstName: selectedUser?.firstName || "",
        lastName: selectedUser?.lastName || "",
        email: selectedUser?.email || "",
        password: password,
        workingCode: selectedUser?.workingCode || "",
        role: selectedUser?.role || UsersRoles.PROCESSOR,
        userThatCheck: selectedUser?.userThatCheck || "",
      })
    );

    props.onClose();
  }

  const handleEditUser = () => {
    const validationErrors = {
      userName: !selectedUser?.userName,
      firstName: !selectedUser?.firstName,
      email: !selectedUser?.email,
      workingCode: selectedUser?.workingCode ? !selectedUser?.workingCode : false,
      role: false
    };
    
    let passwordError = passwordValidator(selectedUser?.password?.trim() ? selectedUser?.password : '')

    if (!Object.values(validationErrors).some((error) => error)) {

      setErrorsPassword({
        text: "",
        alert: false
      })

      if(!selectedUser?.password?.trim()){
        sendUpdatedUser('')
      }
      else {
        if(passwordError == ''){
          sendUpdatedUser(sha3_512(selectedUser?.password))
        }
        else{
          setErrorsPassword({
            text: passwordError,
            alert: true
          })
        }
      }

    } else {
      setErrors(validationErrors);
    }
  };

  const DialogContentForm = (<>
    <TextField
      style={{ marginTop: "24px" }}
      label={t("UsersForms.Username")}
      fullWidth
      margin="normal"
      variant="outlined"
      error={errors.userName}
      helperText={errors.userName && t("common.This field is required")}
      value={selectedUser?.userName}
      name="userName"
      onChange={handleInputChange}
    />
    <TextField
      label={t("UsersForms.First Name")}
      fullWidth
      margin="normal"
      variant="outlined"
      error={errors.firstName}
      helperText={errors.firstName && t("common.This field is required")}
      value={selectedUser?.firstName}
      name="firstName"
      onChange={handleInputChange}
    />

    <TextField
      label={t("UsersForms.Last Name")}
      fullWidth
      margin="normal"
      variant="outlined"
      value={selectedUser?.lastName}
      name="lastName"
      onChange={handleInputChange}
    />

    <TextField
      label={t("UsersForms.Email")}
      fullWidth
      margin="normal"
      variant="outlined"
      error={errors.email}
      helperText={errors.email && t("common.This field is required")}
      value={selectedUser?.email}
      name="email"
      onChange={handleInputChange}
    />

    <TextField
      label={t("UsersForms.Password")}
      fullWidth
      margin="normal"
      variant="outlined"
      type="password"
      error={errorsPassword.alert}
      helperText={errorsPassword.text}
      name="password"
      onChange={handleInputChange}
    />

    <TextField
      label={t("UsersForms.Working Code")}
      fullWidth
      margin="normal"
      variant="outlined"
      error={errors.workingCode}
      helperText={errors.workingCode && t("common.This field is required")}
      value={selectedUser?.workingCode}
      name="workingCode"
      onChange={handleInputChange}
    />

    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel htmlFor="role">{t("UsersForms.role")}</InputLabel>

      <Select
        label={t("UsersForms.role")}
        inputProps={{
          name: "role",
          id: "role",
        }}
        value={selectedUser?.role}
        onChange={(e) => handleSelectChange(e)}
      >
        <MenuItem value={UsersRoles.MANAGER}>{t("UsersForms.roles.manager")}</MenuItem>
        <MenuItem value={UsersRoles.OPERATOR}>{t("UsersForms.roles.operator")}</MenuItem>
        <MenuItem value={UsersRoles.PROCESSOR}>{t("UsersForms.roles.processor")}</MenuItem>
      </Select>
    </FormControl>

  </>)

  return (
    <CustomDialog
      dialogTitle={t("UsersForms.Edit user")}
      dialogContent={DialogContentForm}
      dialogActions={
        <Button
          variant="contained"
          onClick={handleEditUser}
        >
           {t("common.Submit")}
        </Button>
      }
      open={props.open}
      onClose={props.onClose}
    />
  );
}
