import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { UsersRoles } from "../../_constants/UsersRoles";
import { UpdateUserDto } from "../../_types";
import { SelectChangeEvent } from "@mui/material/Select";
import { usersActions, useAppDispatch } from "../../_store";
import { CustomDialog } from "../CustomDialog";
import { useTranslation } from "react-i18next";
import { sha3_512 } from 'js-sha3';
import { passwordValidator } from "../../_helpers";

export function AddUserForm(props: { open: boolean, onClose: Function }) {
  const dispatch = useAppDispatch();
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [workingCode, setWorkingCode] = useState("");
  const [role, setRole] = useState(UsersRoles.PROCESSOR);
  const { t } = useTranslation();

  const [errorsPassword, setErrorsPassword] = useState(
    {
      text: "",
      alert: false
    });

  const [selectedUser, setSelectedUser] = useState<UpdateUserDto | null>(null);

  const [errors, setErrors] = useState({
    userName: false,
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    workingCode: false,
    role: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (selectedUser) {
      setSelectedUser((prevUser) => ({
        ...(prevUser as UpdateUserDto),
        [name]: value,
      }));
    } else {
      switch (name) {
        case "userName":
          setUserName(value);
          break;
        case "firstName":
          setFirstName(value);
          break;
        case "lastName":
          setLastName(value);
          break;
        case "email":
          setEmail(value);
          break;
        case "password":
          setPassword(value);
          break;
        case "workingCode":
          setWorkingCode(value);
          break;
        default:
          break;
      }
    }
  };

  const handleSelectChange = (e: SelectChangeEvent<UsersRoles>) => {
    setRole(e.target.value as UsersRoles);
  };

  const handleSubmit = () => {
    const validationErrors = {
      userName: !userName.trim(),
      firstName: !firstName.trim(),
      lastName: !lastName.trim(),
      email: !email.trim(),
      password: !password.trim(),
      workingCode: workingCode ? !workingCode.trim() : false,
      role: false,
    };

    let passwordError = passwordValidator(password)

    if (!Object.values(validationErrors).some((error) => error) && passwordError == '') {

      setErrorsPassword({
        text: "",
        alert: false
      })

      let hashedPassword: string = sha3_512(password)

      dispatch(
        usersActions.addUser({
          userName,
          firstName,
          lastName,
          email,
          password: hashedPassword,
          workingCode,
          role,
          userThatCheck: null,
        })
      );
      props.onClose();
    } else {

      setErrors(validationErrors);

      setErrorsPassword({
        text: passwordError,
        alert: true
      })

    }
  };
  const DialogContentForm = (<>
    <TextField
      label={t("UsersForms.Username")}
      fullWidth
      margin="normal"
      variant="outlined"
      error={errors.userName}
      helperText={errors.userName && t("common.This field is required")}
      value={userName}
      name="userName"
      onChange={handleInputChange}
    />
    <TextField
      label={t("UsersForms.First Name")}
      fullWidth
      margin="normal"
      variant="outlined"
      error={errors.firstName}
      helperText={errors.firstName && t("common.This field is required")}
      value={firstName}
      name="firstName"
      onChange={handleInputChange}
    />

    <TextField
      label={t("UsersForms.Last Name")}
      fullWidth
      margin="normal"
      variant="outlined"
      error={errors.lastName}
      helperText={errors.lastName && t("common.This field is required")}
      value={lastName}
      name="lastName"
      onChange={handleInputChange}
    />

    <TextField
      label={t("UsersForms.Email")}
      fullWidth
      margin="normal"
      variant="outlined"
      error={errors.email}
      helperText={errors.email && t("common.This field is required")}
      value={email}
      name="email"
      onChange={handleInputChange}
    />

    <TextField
      label={t("UsersForms.Password")}
      fullWidth
      margin="normal"
      variant="outlined"
      type="password"
      error={errorsPassword.alert}
      helperText={errorsPassword.text}
      value={password}
      name="password"
      onChange={handleInputChange}
    />

    <TextField
      label={t("UsersForms.Working Code")}
      fullWidth
      margin="normal"
      variant="outlined"
      error={errors.workingCode}
      helperText={errors.workingCode && t("common.This field is required")}
      value={workingCode}
      name="workingCode"
      onChange={handleInputChange}
    />

    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel htmlFor="role">{t("UsersForms.role")}</InputLabel>

      <Select
        label={t("UsersForms.role")}
        inputProps={{
          name: "role",
          id: "role",
        }}
        value={role}
        onChange={(e) => handleSelectChange(e)}
      >
        <MenuItem value={UsersRoles.MANAGER}>{t("UsersForms.roles.manager")}</MenuItem>
        <MenuItem value={UsersRoles.OPERATOR}>{t("UsersForms.roles.operator")}</MenuItem>
        <MenuItem value={UsersRoles.PROCESSOR}>{t("UsersForms.roles.processor")}</MenuItem>
      </Select>
    </FormControl>
  </>)
  return (
    <CustomDialog
      dialogTitle={t("UsersForms.New user")}
      dialogContent={DialogContentForm}
      dialogActions={
        <Button
          variant="contained"
          onClick={handleSubmit}
        >
          {t("UsersForms.Add User")}
        </Button>
      }
      open={props.open}
      onClose={props.onClose}
    />
  );
}
